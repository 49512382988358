const ManaCost = ({ cost, active }: { cost: string; active?: boolean }) => (
  <div className='relative'>
    <img src={require(`assets/icons/icon-mana${active ? '' : '-deactive'}.png`).default} />
    <div className='font-skadi font-bold text-lg absolute inset-0'>
      <div className='flex items-center justify-center w-full h-full'>
        <div style={{ WebkitTextStroke: '0.992144px #523247' }}>{cost === '-1' ? '?' : cost}</div>
      </div>
    </div>
  </div>
);

export default ManaCost;
