type StyledCheckBoxType = {
  checked?: boolean;
  handleChange: (checked: boolean) => void;
};

const StyledCheckBox = ({ checked, handleChange }: StyledCheckBoxType) => {
  return (
    <div className='flex-none'>
      {checked ? (
        <img src={require('assets/icons/icon-checkbox-yes.png').default} onClick={() => handleChange(false)} className='h-4 md:h-auto'/>
      ) : (
        <img src={require('assets/icons/icon-checkbox-no.png').default} onClick={() => handleChange(true)} className='h-4 md:h-auto'/>
      )}
    </div>
  );
};

export default StyledCheckBox;
