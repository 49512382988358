import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { APP_API } from 'env';
import { store } from 'reducers';
import { ProfileState, signOut } from 'reducers/profile';

const beforeRequest = (config: AxiosRequestConfig) => {
  const { isLoggedIn, token }: ProfileState = store.getState().profile;
  if (isLoggedIn) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`
      }
    }
  }
  if (config.data instanceof FormData) {
    return {
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': 'multipart/form-data'
      }
    }
  }
  return config;
};

const onError = async (error: AxiosError) => {
  const { response } = error;
  if (response) {
    const { status } = response;
    if (status === 401) {
      store.dispatch(signOut());
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
};

const client = axios.create({ baseURL: APP_API });
client.interceptors.request.use(beforeRequest);
client.interceptors.response.use((res: any) => res.data.data, onError);

const clientRaw = axios.create({ baseURL: APP_API });
clientRaw.interceptors.request.use(beforeRequest);
clientRaw.interceptors.response.use((res: any) => res.data.data, onError);

// client.defaults.paramsSerializer = (params) => stringify(decamelizeKeys(params), { arrayFormat: 'brackets' });
// client.defaults.transformRequest = [(data) => decamelizeKeys(data), ...(axios.defaults.transformRequest as [])];
// client.defaults.transformResponse = [...(axios.defaults.transformResponse as []), (data) => camelizeKeys(data)];

export { client, clientRaw };
