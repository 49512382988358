import React from 'react';
import { Button, Checkbox, Dialog, Grid, IconButton, Tooltip } from '@mui/material';
import { DesignPagination } from 'components';
import { useSearch, useIsAccountOwner, useWindowDimensions } from 'hooks';
import { CocoonType } from 'models/Cocoon';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { publicRoute } from 'routes';
import { nftService, queryClient } from 'services';
import { PopupOpenCocoon } from 'views/CocoonView/components';
import { CardCocoon } from 'views/Home/cards';
import { useSnackbar } from 'notistack';
import { Refresh } from '@mui/icons-material';
import { DateTime } from 'luxon';

const InventoryCocoons = ({ address }: { address?: string }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useWindowDimensions();
  const [dataSearch, onSearchChange] = useSearch({ owner: address });
  const [isAccountOwner] = useIsAccountOwner();

  const { data } = useQuery(
    ['nftService.fetchCocoons', dataSearch],
    ({ queryKey }) => nftService.fetchCocoons(queryKey[1]),
    {
      keepPreviousData: true,
    },
  );
  const { nfts = [], total } = data ?? {};

  const { mutate: refreshInventoryCocoonsData, isLoading } = useMutation(nftService.refreshInventoryCocoonsData, {
    onSuccess: () => {
      queryClient.invalidateQueries('nftService.fetchCocoons');
      enqueueSnackbar('Refresh successfully!', { variant: 'success' });
    },
    onError: (e: any) => {
      enqueueSnackbar(e.response?.data?.message ?? 'Refresh failed!', { variant: 'error' });
    },
  });

  const [items, setItems] = useState<CocoonType[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState('');

  const CheckBoxSection = ({ item, disabled }: { item: CocoonType; disabled?: boolean }) => (
    <div className='absolute -right-3 md:right-0 -top-3 md:top-0 p-4'>
      <Checkbox
        disabled={disabled}
        color='warning'
        checked={items.some((next) => next._id === item._id)}
        onChange={(event, checked) => {
          if (checked) {
            if (items.length === 0) {
              setSelectedType(item.type);
            }
            setItems((items) => items.concat(item));
          } else {
            if (items.length === 1) {
              setSelectedType('');
            }
            setItems((items) => items.filter((next) => next._id !== item._id));
          }
        }}
      />
    </div>
  );

  React.useEffect(() => {
    onSearchChange({ owner: address });
  }, [address, onSearchChange]);

  return (
    <div>
      <div className='flex flex-col gap-3 md:gap-0 md:flex-row items-center md:items-start md:justify-between mb-4'>
        <div className='text-color-secondary md:text-white md:text-3xl md:font-semibold'>
          <Tooltip placement='top' title={<div className='text-lg text-color-primary'>Refresh items</div>}>
            <IconButton
              onClick={() => {
                if (nfts.length > 0 && address) {
                  refreshInventoryCocoonsData({ contract: nfts[0].contract, address });
                }
              }}
            >
              <Refresh />
            </IconButton>
          </Tooltip>
          {isLoading ? 'Loading...' : `${total} Cocoon(s)`}
        </div>
        {isAccountOwner && (
          <Button
            variant='outlined'
            color='warning'
            disabled={items.length === 0}
            onClick={() => setOpen(true)}
            className='h-10'
          >
            Open {items.length} cocoons
          </Button>
        )}
      </div>
      {total !== 0 ? (
        <>
          <Grid container spacing={{ xs: 2, md: 5 }}>
            {nfts.map((item) => {
              const isSale = !!item?.sale;
              const isStandardCocoon = item?.type === 'standard-cocoon';
              const isNotCoolDown = (item?.attributes[2]?.value ?? 0) < DateTime.now().toSeconds();
              const disabled = items.length !== 0 && selectedType !== item.type;

              return (
                <Grid item xs={12} md={6} lg={4} xl={3} key={item.tokenId}>
                  <div className='relative'>
                    <Link to={publicRoute.cocoonView.url(item)}>
                      <CardCocoon item={item} />
                    </Link>
                    {isAccountOwner &&
                      !isSale &&
                      (isStandardCocoon ? (
                        isNotCoolDown && <CheckBoxSection item={item} disabled={disabled} />
                      ) : (
                        <CheckBoxSection item={item} disabled={disabled} />
                      ))}
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <div className='flex justify-center mt-10'>
            <DesignPagination
              offset={dataSearch.offset}
              limit={dataSearch.limit}
              total={total}
              onChange={onSearchChange}
            />
          </div>
        </>
      ) : (
        <div className='flex flex-col-reverse md:flex-row items-center md:items-start md:mt-32'>
          <div>
            <img src={require('assets/images/no-item-image.png').default} className='w-40 md:w-full h-auto' />
          </div>
          <div
            className='block text-center pt-6 pb-14'
            style={{
              background: `url(${
                require(`assets/images/${isMobile ? 'no-item-mobile-bubble' : 'no-item-conversation-bubble'}.png`)
                  .default
              }) no-repeat center / contain`,
              width: isMobile ? 310 : 439,
              height: isMobile ? 180 : 234,
            }}
          >
            <div className='font-skadi text-2xl md:text-huge text-white'>
              Let’s get
              <br />
              some cocoons!
            </div>
            <Link
              to={publicRoute.marketplace.path}
              className='text-color-legendary text-xl md:text-2xl'
              style={{ textDecorationColor: 'DA8B14', textDecoration: 'underline' }}
            >
              To marketplace
            </Link>
          </div>
        </div>
      )}

      <Dialog maxWidth='md' open={open}>
        <PopupOpenCocoon items={items} onSuccess={() => setItems([])} onClose={() => setOpen(false)} />
      </Dialog>
    </div>
  );
};

export default InventoryCocoons;
