import { getNewRankColor, modifyRank } from 'utils/common';
import styled, { StyledComponent } from 'styled-components';

const CustomBox: StyledComponent<'div', any, any, any> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-clip: padding-box;
  position: relative;
  height: fit-content;
  border: ${(props: any) => props.borderWidth}px solid transparent;
  :before {
    content: '';
    position: absolute;
    inset: 0;
    margin: -${(props: any) => props.borderWidth}px;
    border-radius: inherit;
  }
`;

const BorderRankColor = ({
  rank,
  children,
  borderRadius,
  borderWidth,
}: {
  rank: string;
  children?: React.ReactNode;
  borderRadius: number;
  borderWidth: number;
}) => {
  const color = getNewRankColor(modifyRank(rank));

  return (
    <CustomBox style={{ background: color, borderRadius }} borderWidth={borderWidth}>
      {children}
    </CustomBox>
  );
};

export default BorderRankColor;
