import { Button, Dialog, Pagination, PaginationItem, Tooltip } from '@mui/material';
import { InfoOutlined, QrCode } from '@mui/icons-material';
import { customizeFloatNumber, shorten } from 'utils/common';
import { Duration } from 'luxon';
import { memo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { scholarshipService } from 'services';
import { GetScholarshipsParams, ScholarshipsHeroType } from 'models/Scholarship';
import { CardHeroMinimized } from 'views/Home/cards';
import { CustomCheckbox, CustomMUIDataTable, ExpansionCell, NoItem, Status, tableHeadRender } from './TableAssigned';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { useSnackbar } from 'notistack';
import { CloseButton, Copiable, DesignButton } from 'components';
import { GAME_URL, OKG_HERO } from 'env';
import { useWindowDimensions } from 'hooks';
import { Link } from 'react-router-dom';
import { publicRoute } from 'routes';

const handleRowHover = (rowIndex: any) => {
  let row = document.getElementById('hoverRow-tableAssignedScholar-' + rowIndex);
  if (!!row) row!.style.display = 'flex';
};

const handleRowHoverLeave = (rowIndex: any) => {
  let row = document.getElementById('hoverRow-tableAssignedScholar-' + rowIndex);
  if (!!row) row!.style.display = 'none';
};

const defaultSearch = {
  currentPage: 1,
  pageSize: 10,
  full: false,
  scholarshipWeb3AddressAssigned: true,
  // isScholarshipActive: true,
};

const TableAssignedScholar = () => {
  const { isMobile } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const { address } = useSelector(profileSelector);
  const [dataSearch, setDataSearch] = useState({
    ...defaultSearch,
    scholarshipWeb3Address: address?.toLocaleLowerCase(),
  });
  const [passCode, setPassCode] = useState('');
  const [chosenUserName, setChosenUserName] = useState('');
  const [openPopupSuccess, setOpenPopupSuccess] = useState(false);

  const { data: scholarships } = useQuery(
    ['scholarshipService.getScholarshipScholarAssigned', dataSearch],
    async ({ queryKey }) => {
      const scholarshipsRes = await scholarshipService.getScholarship(queryKey[1] as GetScholarshipsParams);
      return scholarshipsRes;
    },
    { keepPreviousData: true },
  );

  const { mutate: generatePassword } = useMutation(
    async (scholarshipId: string) => {
      const code = await scholarshipService.generatePassword({ scholarshipId });
      setPassCode(code.code);
    },
    {
      onSuccess: () => {
        setOpenPopupSuccess(true);
        enqueueSnackbar('Generate successfully!', { variant: 'success' });
      },
      onError: (e: any) => {
        enqueueSnackbar(e.response?.data?.message ?? 'Generate failed!', { variant: 'error' });
      },
    },
  );

  const HoverButton = memo(({ status, rowIndex }: { status: number; rowIndex: number }) => {
    const chosenScholarship = scholarships?.items[rowIndex];
    const show = status === 2;

    return show ? (
      <div
        className='flex gap-2 h-full px-4 py-2 items-center justify-center'
        style={{
          background: isMobile ? '#271911' : '#382B24',
        }}
      >
        {status === 2 && (
          <Button
            variant='contained'
            startIcon={<QrCode />}
            onClick={() => {
              setChosenUserName(chosenScholarship?.userName ?? '');
              generatePassword(chosenScholarship?.scholarshipId ?? '');
            }}
          >
            Generate Login Code
          </Button>
        )}
      </div>
    ) : null;
  });

  const columns = [
    {
      name: 'scholarshipId',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
          const dataIndex = currentTableData[rowIndex].index;
          const scholarshipStatus = scholarships?.items[dataIndex].scholarshipStatus;

          return (
            <>
              <Tooltip placement='top' title={<div className='text-lg text-color-primary'>{value}</div>}>
                <div>{shorten(value, 8, 0)}</div>
              </Tooltip>
              <div
                id={`hoverRow-tableAssignedScholar-${dataIndex}`}
                className='absolute right-0'
                style={{
                  top: '50%',
                  transform: 'translate(0, -50%)',
                  display: 'none',
                }}
              >
                <HoverButton status={scholarshipStatus ?? 0} rowIndex={dataIndex} />
              </div>
            </>
          );
        },
      },
    },
    {
      name: 'userName',
      label: 'Account',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: any, { rowIndex }: any) => (
          <Tooltip placement='top' title={<div className='text-lg text-color-primary'>{value}</div>}>
            <div>{shorten(value, 15, 0, 15)}</div>
          </Tooltip>
        ),
      },
    },
    {
      name: 'heroes',
      label: 'Heroes',
      options: {
        filter: true,
        sort: false,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: ScholarshipsHeroType[], { rowIndex }: any) => (
          <div className='flex gap-2'>
            {!!value &&
              value.map((hero: ScholarshipsHeroType, index: number) => (
                <div key={index}>
                  <Link to={publicRoute.heroView.url({ contract: OKG_HERO, tokenId: hero.tokenId })}>
                    <CardHeroMinimized item={hero} />
                  </Link>
                </div>
              ))}
          </div>
        ),
      },
    },
    {
      name: 'totalMatch',
      label: 'Total Matches',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
      },
    },
    {
      name: 'winRate',
      label: 'Win Rate',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: any, { rowIndex }: any) => (
          <div {...(value >= 1 ? { style: { color: '#6CDD37' } } : {})}>{`${customizeFloatNumber(value ?? 0)}%`}</div>
        ),
      },
    },
    {
      name: 'ratio',
      label: '',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any, sortOrder: any) =>
          tableHeadRender(columnMeta, handleToggleColumn, sortOrder, () => (
            <div className='flex gap-1 items-start'>
              Split Rate
              <Tooltip
                placement='top'
                title={
                  <div className='text-base text-color-secondary p-2'>Split Rate: % to scholar - % to manager</div>
                }
                componentsProps={{ tooltip: { className: 'bg-color-dark' }, arrow: { className: 'text-color-dark' } }}
              >
                <InfoOutlined style={{ fontSize: 15 }} />
              </Tooltip>
            </div>
          )),
        customBodyRender: (value: any, { rowIndex }: any) => <div>{`${value}/${100 - value}`}</div>,
      },
    },
    {
      name: 'scholarshipKab',
      label: 'Total Earned gKAB',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: any, { rowIndex }: any) => (
          <div>{Number(customizeFloatNumber(value ?? 0)).toLocaleString()}</div>
        ),
      },
    },
    {
      name: 'scholarshipStatus',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
          const dataIndex = currentTableData[rowIndex].index;

          return (
            <div className='flex flex-col gap-1'>
              <Status status={value ?? 0} />
              <div className='text-color-primary text-sm'>
                {`${Duration.fromObject({ seconds: scholarships?.items[dataIndex].totalPlayedSeconds ?? 0 }).toFormat(
                  'h',
                )} hours played`}
              </div>
            </div>
          );
        },
      },
    },
  ];

  const mobileColumn = [
    {
      name: 'userName',
      label: 'Account',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
          return (
            <>
              <Tooltip placement='top' title={<div className='text-lg text-color-primary'>{value}</div>}>
                <div>{shorten(value, 15, 0, 15)}</div>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: 'heroes',
      label: 'Heroes',
      options: {
        filter: true,
        sort: false,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: ScholarshipsHeroType[], { rowIndex }: any) => (
          <div className='flex gap-2'>
            {!!value &&
              value.map((hero: ScholarshipsHeroType, index: number) => (
                <div key={index}>
                  <Link to={publicRoute.heroView.url({ contract: OKG_HERO, tokenId: hero.tokenId })}>
                    <CardHeroMinimized item={hero} />
                  </Link>
                </div>
              ))}
          </div>
        ),
      },
    },
    {
      name: 'winRate',
      label: 'Win Rate',
      options: {
        filter: true,
        sort: true,
        customHeadRender: tableHeadRender,
        customBodyRender: (value: any, { rowIndex }: any) => (
          <div {...(value >= 1 ? { style: { color: '#6CDD37' } } : {})}>{`${customizeFloatNumber(value ?? 0)}%`}</div>
        ),
      },
    },
  ];

  return (
    <>
      {!!scholarships ? (
        scholarships?.items.length === 0 ? (
          <NoItem />
        ) : (
          <>
            <div className='mb-3'>
              {isMobile ? (
                <CustomMUIDataTable
                  title=''
                  data={scholarships.items}
                  columns={mobileColumn}
                  options={{
                    selectableRows: undefined,
                    expandableRows: true,
                    responsive: 'standard',
                    elevation: 0,
                    download: false,
                    filter: false,
                    pagination: false,
                    search: false,
                    print: false,
                    viewColumns: false,
                    renderExpandableRow: (
                      rowData: string[],
                      rowMeta: {
                        dataIndex: number;
                        rowIndex: number;
                      },
                    ) => {
                      const expandScholarship = scholarships.items[rowMeta.dataIndex];

                      return (
                        <tr className='text-xs' style={{ background: '#1C110C' }}>
                          <td />
                          <td className='pr-12 py-2' colSpan={3}>
                            <div className='flex flex-col gap-2'>
                              <ExpansionCell
                                title='ID'
                                value={shorten(expandScholarship?.scholarshipId ?? '', 25, 0, 25)}
                              />
                              <ExpansionCell
                                title='Total Matches'
                                value={`${expandScholarship?.totalMatch ?? 0}`}
                                evenChild
                              />
                              <ExpansionCell
                                title='Split rate'
                                value={`${expandScholarship?.ratio}/${100 - expandScholarship?.ratio}`}
                              />
                              <ExpansionCell
                                title='Total Earned'
                                value={
                                  <div>
                                    {Number(
                                      customizeFloatNumber(expandScholarship?.scholarshipKab ?? 0),
                                    ).toLocaleString()}
                                  </div>
                                }
                                evenChild
                              />
                              <ExpansionCell
                                title='Status'
                                value={
                                  <div className='flex flex-col gap-1'>
                                    <Status status={expandScholarship?.scholarshipStatus ?? 0} />
                                    <div className='text-color-primary'>
                                      {`${Duration.fromObject({
                                        seconds: expandScholarship?.totalPlayedSeconds ?? 0,
                                      }).toFormat('h')} hours played`}
                                    </div>
                                  </div>
                                }
                              />
                              <HoverButton
                                status={expandScholarship?.scholarshipStatus ?? 0}
                                rowIndex={rowMeta.dataIndex}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    },
                  }}
                />
              ) : (
                <CustomMUIDataTable
                  title=''
                  data={scholarships.items}
                  columns={columns}
                  components={{
                    Checkbox: CustomCheckbox,
                  }}
                  options={{
                    selectableRows: undefined,
                    elevation: 0,
                    download: false,
                    filter: false,
                    pagination: false,
                    search: false,
                    print: false,
                    viewColumns: false,
                    setRowProps: (row, dataIndex, rowIndex) => {
                      return {
                        className: 'relative',
                        onMouseEnter: () => handleRowHover(dataIndex),
                        onMouseLeave: () => handleRowHoverLeave(dataIndex),
                      };
                    },
                  }}
                />
              )}
            </div>
            <div className='flex justify-center'>
              <Pagination
                size='large'
                color='secondary'
                page={scholarships.paginationPage}
                count={scholarships.paginationCount}
                onChange={(event, page) => {
                  if (page > 0) setDataSearch({ ...dataSearch, currentPage: page });
                }}
                renderItem={(item) => <PaginationItem {...item} classes={{ root: 'font-bold' }} />}
              />
            </div>
            <Dialog maxWidth='md' open={openPopupSuccess}>
              <div className='flex flex-col items-center p-4 md:p-12'>
                <div className='text-white font-skadi text-xl md:text-3xl text-center mb-4 md:mb-8'>
                  Scholarship’s game account
                </div>
                <div className='text-white font-skadi text-xs md:text-base w-full mb-4 md:mb-8'>
                  Login game account to play and earn now.
                </div>
                <div
                  className='w-full text-xs md:text-base p-3 md:p-4 flex flex-col gap-3 md:gap-5 mb-4 md:mb-8'
                  style={{ background: '#553F33', borderRadius: 16 }}
                >
                  <div className='flex justify-between items-center'>
                    <div className='text-color-primary font-black'>USERNAME</div>
                    <Copiable address={chosenUserName} />
                  </div>
                  <div className='flex justify-between items-center'>
                    <div className='text-color-primary font-black'>LOGIN CODE</div>
                    <Copiable address={passCode} />
                  </div>
                </div>
                <DesignButton
                  fullWidth
                  design='yellow'
                  size={isMobile ? 'medium' : 'large'}
                  className='w-48 md:w-60'
                  onClick={() => {
                    window.open(GAME_URL ?? '', '_blank');
                  }}
                >
                  PLAY NOW
                </DesignButton>
                <CloseButton onClick={() => setOpenPopupSuccess(false)} />
              </div>
            </Dialog>
          </>
        )
      ) : null}
    </>
  );
};

export default TableAssignedScholar;
