import { SyntheticEvent, useCallback, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type TabType = {
  code: string;
  label: React.ReactNode;
  component: React.ReactNode;
};

const useTabs = (tabs: TabType[], name: string = 'tab') => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = Object.fromEntries(new URLSearchParams(location.search));

  const getCurrentTab = useCallback(
    () => (tabs.find((item) => item.code === params[name]) ?? tabs[0]).code,
    [params, name, tabs],
  );

  const [activeTab, setActiveTab] = useState(getCurrentTab());

  useLayoutEffect(() => {
    const currentTab = getCurrentTab();
    setActiveTab(currentTab);
  }, [getCurrentTab]);

  const handleChangeTab = useCallback(
    (event: any, value: any) => {
      setActiveTab(value);
      navigate(
        {
          search: new URLSearchParams({ ...params, [name]: value }).toString(),
        },
        { replace: true },
      );
    },
    [name, navigate, params],
  );

  return [activeTab, handleChangeTab] as [any, (event: SyntheticEvent<Element, Event>, value: any) => void];
};

export default useTabs;
