import { CircularProgress, Dialog, DialogContent, Typography } from '@mui/material';
import { DesignButton } from 'components';
import { web3 } from 'contracts';
import { CHAIN_ID } from 'env';
import { useWindowDimensions } from 'hooks';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { walletService } from 'services';

export const APP_NETWORK =
  CHAIN_ID === '0x38'
    ? {
        chainName: 'Binance Smart Chain',
        chainId: '0x38',
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com/'],
        nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
      }
    : {
        chainName: 'BSC Testnet',
        chainId: '0x61',
        rpcUrls: ['https://bsc-testnet.public.blastapi.io'],
        blockExplorerUrls: ['https://testnet.bscscan.com/'],
        nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
      };

const NetworkBar = () => {
  const { isMobile } = useWindowDimensions();
  const dispatch = useDispatch();
  const [isWrongNetwork, setIsWrongNetwork] = useState(false);

  const getNetwork = async () => {
    await walletService.connectProvider();
    const netId = await web3.eth.net.getId();
    const isRightNet = parseInt(netId?.toString()) === Number(CHAIN_ID);
    setIsWrongNetwork(!isRightNet);
  };

  const handleConnectBinance = async () => {
    try {
      if (APP_NETWORK.chainId !== window.ethereum.chainId)
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: APP_NETWORK.chainId }],
        });
      else {
      }
    } catch (error: any) {
      if (error.code === 4902) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [APP_NETWORK],
        });
      }
    }
  };

  useEffect(() => {
    window.ethereum?.on('chainChanged', getNetwork);
    getNetwork();
  }, [dispatch]);

  return (
    <div>
      <Dialog fullWidth maxWidth='xs' open={isWrongNetwork}>
        <DialogContent>
          <div className='flex flex-col items-center py-8'>
            <CircularProgress />
            <Typography variant='h3' className='mt-4 mb-2'>
              Wrong Network
            </Typography>
            <Typography className='mb-6'>Please switch network to continue</Typography>
            {!isMobile && (
              <DesignButton
                startIcon={<img src={require('assets/icons/binance.svg').default} />}
                onClick={handleConnectBinance}
              >
                BNB Chain
              </DesignButton>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NetworkBar;
