export const APP_API = process.env.REACT_APP_APP_API;
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
export const REDEEM_CONTRACT = process.env.REACT_APP_REDEEM_CONTRACT;
export const OKG_TOKEN_ADDRESS = process.env.REACT_APP_OKG_TOKEN;
export const OKG_SWAP = process.env.REACT_APP_OKG_SWAP;
export const OKG_MARKET = process.env.REACT_APP_OKG_MARKET;
export const NFT_CONTRACT = process.env.REACT_APP_NFT_CONTRACT;
export const BREEDING_CONTRACT = process.env.REACT_APP_BREEDING_CONTRACT;
export const KAB_TOKEN_ADDRESS = process.env.REACT_APP_KAB_TOKEN;
export const KAB_SWAP = process.env.REACT_APP_KAB_SWAP;
export const OKG_BREEDING_COCOON_CONTRACT = process.env.REACT_APP_OKG_BREEDING_COCOON_CONTRACT;
export const OKG_HERO = process.env.REACT_APP_OKG_HERO;
export const CLAIM_GIFT_CONTRACT = process.env.REACT_APP_CLAIM_GIFT_CONTRACT;
export const SCHOLARSHIP_CONTRACT = process.env.REACT_APP_SCHOLARSHIP_CONTRACT;
export const GAME_URL = process.env.REACT_APP_GAME_URL;
