import { DateTime } from 'luxon';
import { publicRoute } from 'routes';

export const shorten = (address: string = '', before: number = 6, after: number = 6, offNumber: number = 10) => {
  if (address.length <= offNumber) return address;
  return address.slice(0, before) + '...' + address.slice(address.length - after);
};

export const getBase64 = (file: File, callback: Function) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(file);
};

const rankColorMap = {
  warrior: 'common',
  warmonger: 'uncommon',
  overseer: 'rare',
  chieftain: 'epic',
  chieftan: 'epic',
};

export const heroRankMap = [
  'Warrior',
  'Warmonger',
  'Overseer',
  'Chieftain',
  'Warlord',
  'Elder',
  'WarChief',
  'HighChief',
  'GrandChief',
];

export const getRankColor = (rank: string) => {
  return (rankColorMap as any)[rank];
};

const newRankColorMap: any = {
  warrior: '#F1E9DC',
  warmonger: '#6FAF51',
  overseer: '#62B1F9',
  chieftain: '#C261F0',
  warlord: 'linear-gradient(180deg, #FFF7EA 0%, #A36C02 100%)',
  elder: 'linear-gradient(180deg, #4FDF89 0%, #30337E 100%)',
  warchief: 'linear-gradient(180deg, #62B1F9 0%, #DB62F9 100%) ',
  highchief: 'linear-gradient(180deg, #FF613F 0%, #974024 100%)',
  grandchief: 'linear-gradient(96.47deg, #EBC336 6.04%, #368F8A 34.71%, #7292E3 62.32%, #FF8F3F 85.81%)',
};

export const getNewRankColor = (rank: string) => newRankColorMap[rank];

export const modifyRank = (rank: string) => {
  return rank?.replace(/ /g, '')?.toLowerCase();
};

const timeStamp = '16/05/2022 04:45:00';

export const getTimeLink = (url: string) => {
  const isOutOfDate = DateTime.utc() > DateTime.fromFormat(timeStamp, 'dd/MM/yyyy HH:mm:ss', { zone: 'utc' });
  return isOutOfDate ? url : publicRoute.comingSoon.path;
};

export const getUniqueAfterMerge = (arr1: string[], arr2: string[]): string[] => {
  // merge two arrays
  const arr = (arr1 || []).concat(arr2 || []);
  const uniqueArr = [];
  // loop through array
  for (let i of arr) {
    if (uniqueArr.indexOf(i) === -1) {
      uniqueArr.push(i);
    }
  }
  return uniqueArr;
};

export const customizeNumber = (value: number): string => {
  let finalValue = value.toLocaleString();
  const stringNumber = value.toLocaleString('fullwide', { useGrouping: false });
  if (stringNumber.length > 6 && stringNumber.length < 10) {
    finalValue = `${(Math.trunc(value / 1e5) / 10).toLocaleString()} M`;
  } else if (stringNumber.length >= 10 && stringNumber.length < 14) {
    finalValue = `${(Math.trunc(value / 1e8) / 10).toLocaleString()} B`;
  } else if (stringNumber.length >= 14) {
    finalValue = `${(Math.trunc(value / 1e13) / 10).toLocaleString()} T`;
  }
  return finalValue;
};

export const customizeFloatNumber = (value: number, keep: number = 2): string => {
  let finalValue = value.toString();
  const split = finalValue.split('.');
  if (split.length > 1) {
    finalValue = Number(`${split[0]}.${split[1].substring(0, keep)}`).toString();
  }
  return finalValue;
};

export const currencyFormat = (value: number): string => {
  return value.toLocaleString(undefined, { maximumFractionDigits: 20 });
};

export const atLeastTwoDigits = (value: number): string => {
  return String(value).padStart(2, '0')
}

export const validSignature = (signature: string | any[]) => {
  let suffix = signature.slice(-2);
  if (suffix !== '1b' && suffix !== '1c') {
    suffix = (Number(signature.slice(-2)) + 27).toString(16);
  }
  return signature.slice(0, signature.length - 2) + suffix;
};
