import {
  CocoonGetParams,
  CocoonOpenBody,
  CocoonOpenType,
  CocoonPaginateParams,
  CocoonPaginateType,
  CocoonRefreshParams,
  CocoonType,
  CocoonBuyType,
  CocoonSellParams,
  CocoonBuyParams,
} from 'models/Cocoon';
import {
  HeroGetParams,
  HeroSellParams,
  HeroBuyParams,
  HeroPaginateParams,
  HeroPaginateType,
  HeroType,
  HeroClassType,
  HeroRaceType,
  HeroRefreshParams,
  HeroBuyType,
  HeroRedeemType,
  HeroRedeemParams,
  BreedingParams,
  EstimateBreedingType,
  BreedingType,
  MinHeroSalePriceType,
} from 'models/Hero';
import { client, clientRaw } from './axios';
import { stringify } from 'query-string';

const fetchHeroes = (params?: HeroPaginateParams): Promise<HeroPaginateType> =>
  client.get(`/api/heroes`, {
    params,
    paramsSerializer: (params) => stringify(params, { skipNull: true, skipEmptyString: true, arrayFormat: 'comma' }),
  });
const fetchHeroClasses = (): Promise<HeroClassType> => client.get(`/api/systems/heroes/classes`);
const fetchHeroRaces = (): Promise<HeroRaceType> => client.get(`/api/systems/heroes/races`);
const fetchHeroRanks = (): Promise<HeroRaceType> => client.get(`/api/systems/heroes/ranks`);
const fetchCocoons = (params?: CocoonPaginateParams): Promise<CocoonPaginateType> =>
  client.get(`/api/cocoons`, {
    params,
    paramsSerializer: (params) => stringify(params, { skipNull: true, skipEmptyString: true, arrayFormat: 'comma' }),
  });

const getHeroToken = ({ contract, tokenId }: HeroGetParams): Promise<HeroType> =>
  client.get(`/api/heroes/${contract}/${tokenId}`);
const getCocoonToken = ({ contract, tokenId }: CocoonGetParams): Promise<CocoonType> =>
  client.get(`/api/cocoons/${contract}/${tokenId}`);

const openCocoon = (body: CocoonOpenBody): Promise<CocoonOpenType> => client.post(`/api/cocoons/open`, body);
const refreshCocoonData = ({ contract, tokenId }: CocoonGetParams) =>
  client.post(`/api/cocoons/${contract}/${tokenId}/refresh`);
const refreshInventoryCocoonsData = ({ contract, address }: CocoonRefreshParams) =>
  client.post(`/api/cocoons/${contract}/sync/user/${address}`);
const refreshHeroData = ({ contract, tokenId }: HeroGetParams) =>
  client.post(`/api/heroes/${contract}/${tokenId}/refresh`);
const refreshInventoryHeroesData = ({ contract, address }: HeroRefreshParams) =>
  client.post(`/api/heroes/${contract}/sync/user/${address}`);

const sellHero = ({ contract, tokenId, price }: HeroSellParams) =>
  client.post(`/api/heroes/${contract}/${tokenId}/sell`, { price });
const buyHero = ({ saleId }: HeroBuyParams): Promise<HeroBuyType> => client.post(`/api/heroes/sales/${saleId}/buy`);
const deleteSaleHero = ({ saleId }: HeroBuyParams) => client.delete(`/api/heroes/sales/${saleId}`);
const getHeroMinPrice = ({ contract, tokenId }: HeroGetParams): Promise<MinHeroSalePriceType> =>
  client.get(`/api/heroes/${contract}/${tokenId}/min-price`);

const sellCocoon = ({ contract, tokenId, price }: CocoonSellParams) =>
  client.post(`/api/cocoons/${contract}/${tokenId}/sell`, { price });
const buyCocoon = ({ saleId }: CocoonBuyParams): Promise<CocoonBuyType> =>
  client.post(`/api/cocoons/sales/${saleId}/buy`);
const deleteSaleCocoon = ({ saleId }: CocoonBuyParams) => client.delete(`/api/cocoons/sales/${saleId}`);

const redeemHero = ({ bHeroTokenId }: HeroRedeemParams): Promise<HeroRedeemType> =>
  clientRaw.post(`/api/bheroes/redeem`, { bHeroTokenId });

const estimateBreeding = (params: BreedingParams): Promise<EstimateBreedingType> =>
  client.get(`/api/heroes/breed/estimate`, { params });
const breed = (body: BreedingParams): Promise<BreedingType> => client.post(`/api/heroes/breed`, body);

export default {
  fetchHeroes,
  fetchHeroClasses,
  fetchHeroRaces,
  fetchHeroRanks,
  fetchCocoons,

  getHeroToken,
  getCocoonToken,

  openCocoon,
  refreshCocoonData,
  refreshInventoryCocoonsData,
  refreshHeroData,
  refreshInventoryHeroesData,

  sellHero,
  buyHero,
  deleteSaleHero,
  getHeroMinPrice,

  sellCocoon,
  buyCocoon,
  deleteSaleCocoon,

  redeemHero,

  estimateBreeding,
  breed,
};
