import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { createTheme, ThemeProvider } from '@mui/material';

export const appTheme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: 'div',
          body2: 'div',
          inherit: 'div',
        },
      },
      styleOverrides: {
        h1: { fontSize: 32, fontWeight: 600 },
        h2: { fontSize: 24, fontWeight: 600 },
        h3: { fontSize: 20, fontWeight: 600 },
        h4: { fontSize: 18, fontWeight: 600 },
        h5: { fontSize: 16, fontWeight: 600 },
        h6: { fontSize: 14, fontWeight: 600 },
        subtitle1: { fontSize: 16, fontWeight: 700, lineHeight: 1.5 },
        subtitle2: { fontSize: 14, fontWeight: 700, lineHeight: 1.43 },
        body1: { fontSize: 16 },
        body2: { fontSize: 14 },
        caption: { fontSize: 12 },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: false,
      },
      styleOverrides: {
        tooltip: {
          fontSize: 14,
          backgroundColor: '#243051',
          border: '1px solid #42517E',
          padding: 16,
          borderRadius: 10,
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },
    MuiPagination: {
      defaultProps: {
        variant: 'outlined',
        shape: 'rounded',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
      },
    },
  },
  typography: {
    fontFamily: `Avenir`,
  },
  palette: {
    mode: 'dark',
    info: {
      main: '#544940',
    },
    primary: {
      main: '#966740',
    },
    secondary: {
      main: '#B7A284',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: 1200,
      xl: 1536,
    },
  },
});

const Theme = ({ children }: any) => {
  return (
    <ThemeProvider theme={appTheme}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>{children}</LocalizationProvider>
    </ThemeProvider>
  );
};

export default Theme;
