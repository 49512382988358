import React from 'react';
import { Grid, IconButton, MenuItem, TextField, Tooltip } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { DesignPagination } from 'components';
import { useSearch, useWindowDimensions } from 'hooks';
import { useMutation, useQuery } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { publicRoute } from 'routes';
import { nftService, queryClient } from 'services';
import { CardHero } from 'views/Home/cards';
import { parse, stringify } from 'query-string';
import { useSnackbar } from 'notistack';

const statusList = [
  { name: 'Listing', code: 'listing', param: 'saleType', value: 'sale' },
  { name: 'On scholarship', code: 'locked', param: 'lockStatus', value: 'locked' },
  { name: 'Not listed', code: 'notListed', param: 'saleType', value: 'not_sale' },
];

const orderByList = [
  { name: 'Rank: Highest', icon: <></>, code: 'rank-true' },
  { name: 'Rank: Lowest', icon: <></>, code: 'rank-false' },
  { name: 'Level: Highest', icon: <></>, code: 'level-true' },
  { name: 'Level: Lowest', icon: <></>, code: 'level-false' },
  { name: 'Breed count: Highest', icon: <></>, code: 'breedCount-true' },
  { name: 'Breed count: Lowest', icon: <></>, code: 'breedCount-false' },
  { name: 'Combat power: Highest', icon: <></>, code: 'power-true' },
  { name: 'Combat power: Lowest', icon: <></>, code: 'power-false' },
];

const InventoryHeroes = ({ address }: { address?: string }) => {
  const { search } = useLocation();
  const { isMobile } = useWindowDimensions();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { tab, ...defaultSearch } = parse(search);
  const defaultOrder = defaultSearch.orderBy && defaultSearch.desc && `${defaultSearch.orderBy}-${defaultSearch.desc}`;
  const defaultStatus =
    !defaultSearch.lockStatus && !defaultSearch.saleType
      ? ' '
      : (defaultSearch.lockStatus && statusList[1].code) ||
        (defaultSearch.saleType && (statusList.find((item) => item.value === defaultSearch.saleType)?.code ?? ' '));

  const [dataSearch, onSearchChange] = useSearch({
    owner: address,
    classType: '',
    raceType: '',
    orderBy: 'rank',
    desc: true,
    ...defaultSearch,
  });
  const [orderBy, setOrderBy] = React.useState(defaultOrder ?? orderByList[0].code);
  const [classType, setClassType] = React.useState(defaultSearch.classType ?? ' ');
  const [raceType, setRaceType] = React.useState(defaultSearch.raceType ?? ' ');
  const [status, setStatus] = React.useState(defaultStatus);

  const { data: dataClasses } = useQuery(['nftService.fetchHeroClasses'], () => nftService.fetchHeroClasses());
  const { data: dataRaces } = useQuery(['nftService.fetchHeroRaces'], () => nftService.fetchHeroRaces());
  const { data } = useQuery(
    ['nftService.fetchHeroes', dataSearch],
    ({ queryKey }) => nftService.fetchHeroes(queryKey[1]),
    {
      keepPreviousData: true,
    },
  );
  const { nfts = [], total } = data ?? {};

  const { mutate: refreshInventoryHeroesData, isLoading } = useMutation(nftService.refreshInventoryHeroesData, {
    onSuccess: () => {
      queryClient.invalidateQueries('nftService.fetchHeroes');
      enqueueSnackbar('Refresh successfully!', { variant: 'success' });
    },
    onError: (e: any) => {
      enqueueSnackbar(e.response?.data?.message ?? 'Refresh failed!', { variant: 'error' });
    },
  });

  React.useEffect(() => {
    if (tab === 'heroes') {
      const { limit, ...selectiveData } = dataSearch;
      navigate({
        search: stringify({ ...selectiveData, tab }, { skipNull: true, skipEmptyString: true }),
      });
    }
  }, [dataSearch, navigate, tab]);

  React.useEffect(() => {
    onSearchChange({ owner: address });
  }, [address, onSearchChange]);

  return (
    <div>
      <div className='flex flex-col gap-3 md:flex-row md:justify-between items-center md:items-start mb-4'>
        <div className='text-color-secondary md:text-white md:text-3xl md:font-semibold'>
          <Tooltip placement='top' title={<div className='text-lg text-color-primary'>Refresh items</div>}>
            <IconButton
              onClick={() => {
                if (nfts.length > 0 && address) {
                  refreshInventoryHeroesData({ contract: nfts[0].contract, address });
                }
              }}
            >
              <Refresh />
            </IconButton>
          </Tooltip>
          {isLoading ? 'Loading...' : `${total} Hero(es)`}
        </div>
        <div className='w-full md:w-auto grid grid-cols-2 md:flex gap-4 md:gap-6'>
          <TextField
            select
            className='bg-color-brown w-full md:w-48 rounded'
            value={classType}
            onChange={(e) => {
              setClassType(e.target.value);
              onSearchChange({ classType: e.target.value.trim() });
            }}
          >
            <MenuItem value=' '>All classes</MenuItem>
            {dataClasses &&
              dataClasses.classes.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            select
            className='bg-color-brown w-full md:w-48 rounded'
            value={raceType}
            onChange={(e) => {
              setRaceType(e.target.value);
              onSearchChange({ raceType: e.target.value.trim() });
            }}
          >
            <MenuItem value=' '>All races</MenuItem>
            {dataRaces &&
              dataRaces.races.map((race, index) => (
                <MenuItem key={index} value={race}>
                  {race}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            select
            className='bg-color-brown w-full md:w-48 rounded'
            value={orderBy}
            onChange={(e) => {
              const values = e.target.value.split('-');
              setOrderBy(e.target.value);
              onSearchChange({ orderBy: values[0], desc: values[1].includes('true') });
            }}
          >
            {orderByList.map((item, index) => (
              <MenuItem key={index} value={item.code}>
                <div className='flex items-center'>
                  {item.name}
                  {item.icon}
                </div>
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            className='bg-color-brown w-full md:w-48 rounded'
            value={status}
            onChange={(e) => {
              const chosenStatus = statusList.find((item) => item.code === e.target.value);
              const statusDataSearch = chosenStatus ? { [chosenStatus.param]: chosenStatus.value } : {};
              const defaultStatuses = {
                saleType: 'all',
                lockStatus: !!chosenStatus ? 'unlocked' : 'all',
              };
              setStatus(e.target.value);
              onSearchChange({ ...defaultStatuses, ...statusDataSearch });
            }}
          >
            <MenuItem value=' '>All status</MenuItem>
            {statusList.map((item, index) => (
              <MenuItem key={index} value={item.code}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      {total !== 0 ? (
        <>
          <Grid container spacing={{ xs: 2, md: 5 }}>
            {nfts.map((item) => (
              <Grid item xs={12} md={6} lg={4} xl={3} key={item.tokenId}>
                <Link to={publicRoute.heroView.url(item)}>
                  <CardHero item={item} />
                </Link>
              </Grid>
            ))}
          </Grid>
          <div className='flex justify-center mt-10'>
            <DesignPagination
              offset={dataSearch.offset}
              limit={dataSearch.limit}
              total={total}
              onChange={onSearchChange}
            />
          </div>
        </>
      ) : (
        <div className='flex flex-col-reverse md:flex-row items-center md:items-start md:mt-32'>
          <div>
            <img src={require('assets/images/no-item-image.png').default} className='w-40 md:w-full h-auto' />
          </div>
          <div
            className='block text-center pt-6 pb-14'
            style={{
              background: `url(${
                require(`assets/images/${isMobile ? 'no-item-mobile-bubble' : 'no-item-conversation-bubble'}.png`)
                  .default
              }) no-repeat center / contain`,
              width: isMobile ? 310 : 439,
              height: isMobile ? 180 : 234,
            }}
          >
            <div className='font-skadi text-2xl md:text-huge text-white'>
              Let’s get
              <br />
              some heroes!
            </div>
            <Link
              to={publicRoute.marketplace.path}
              className='text-color-legendary text-xl md:text-2xl'
              style={{ textDecorationColor: 'DA8B14', textDecoration: 'underline' }}
            >
              To marketplace
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default InventoryHeroes;
