import { styled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { CloseButton, DesignButton } from 'components';
import { useWindowDimensions } from 'hooks';
import { useMemo } from 'react';
import { sumBy } from 'utils';
import { customizeFloatNumber } from 'utils/common';

const StyledTableCell = styled(TableCell)`
  border-color: #45342c;
  color: #f1e9dc;
  text-align: center;
  background-color: #271911;
  @media (max-width: 800px) {
    font-size: 10px;
    background-color: #170a02cc;
  }
`;

const StyledHeaderCell = styled(TableCell)`
  border-color: #574239;
  color: #b7a284;
  text-align: center;
  font-weight: 900;
  background-color: rgba(23, 10, 2, 0.8);
  @media (max-width: 800px) {
    font-size: 10px;
  }
`;

const PopupClaim = ({
  onClose,
  handleClick,
  selectedScholarship,
}: {
  onClose: () => void;
  handleClick: () => void;
  selectedScholarship: any;
}) => {
  const { isMobile } = useWindowDimensions();
  const sumToken = useMemo(() => sumBy('kab', selectedScholarship), [selectedScholarship]);

  return (
    <div className='px-4 md:px-8 py-10 text-color-secondary'>
      <div className='font-skadi text-xl md:text-3xl mb-4 md:mb-8 text-center'>CLAIM gKAB TO MAIN ACCOUNT</div>
      <div className='flex gap-3 items-center text-2xl md:text-huge font-extrabold text-color-greenish justify-center mb-4 md:mb-8'>
        <img
          src={require(`assets/icons/KAB-token.png`).default}
          style={{ width: isMobile ? 32 : 40, height: isMobile ? 32 : 40 }}
        />
        <div>
          {customizeFloatNumber(sumToken ?? 0)} <span className='text-base md:text-xl'>gKAB</span>
        </div>
      </div>
      <div className='overflow-auto mb-4 md:mb-8' style={{ maxHeight: 305, border: '1px solid #45342C', borderRadius: 8 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledHeaderCell>Scholarship</StyledHeaderCell>
              <StyledHeaderCell>Amount</StyledHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedScholarship.map((item: any, index: number) => {
              return (
                <TableRow key={index}>
                  <StyledTableCell>{item?.scholarshipName ?? ''}</StyledTableCell>
                  <StyledTableCell>{`${customizeFloatNumber(item?.kab ?? 0)} gKAB`}</StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>

      <DesignButton
        fullWidth
        design={sumToken === 0 ? 'gray' : 'yellow'}
        size={isMobile ? 'medium' : 'large'}
        onClick={() => {
          if (sumToken !== 0) {
            handleClick();
            onClose();
          }
        }}
      >
        CLAIM
      </DesignButton>
      <CloseButton onClick={onClose} />
    </div>
  );
};

export default PopupClaim;
