import { stringify } from 'query-string';
import {
  CreateScholarshipParams,
  CreateSubAccParams,
  GetScholarshipsParams,
  GetScholarStatusParams,
  GetSubAccsParams,
  ScholarshipsResType,
  ScholarStatusResType,
  SubAccResType,
  ScholarshipsType,
  GetScholarshipByIdParams,
  GetScholarApplicationParams,
  ApplicationType,
  ApproveAplicationParams,
  CancelScholarshipParams,
  GeneratePasswordResType,
  CreateSignatureResType,
  CreateScholarshipResType,
  TransferGKABParams,
  ClaimScholarshipParams,
} from 'models/Scholarship';
import { client } from './axios';

const getScholarship = (params: GetScholarshipsParams): Promise<ScholarshipsResType> =>
  client.get(`/api/scholars/scholarships`, { params });
const getScholarStatus = (params: GetScholarStatusParams): Promise<ScholarStatusResType> =>
  client.get(`/api/scholars/scholarships/scholar-status`, {
    params,
    paramsSerializer: (params) => stringify(params, { skipNull: true, skipEmptyString: true, arrayFormat: 'comma' }),
  });
const createScholarship = (body: CreateScholarshipParams): Promise<CreateScholarshipResType> =>
  client.post(`/api/scholars/scholarships`, body);
const getScholarshipById = ({ scholarshipId }: GetScholarshipByIdParams): Promise<ScholarshipsType> =>
  client.get(`/api/scholars/scholarships/${scholarshipId}`);
const editScholarship = ({ scholarshipId, ...params }: GetScholarshipByIdParams & CreateScholarshipParams) =>
  client.put(`/api/scholars/scholarships/${scholarshipId}`, { ...params });
const getScholarApplication = (params: GetScholarApplicationParams): Promise<ApplicationType> =>
  client.get(`/api/scholars/scholarships/applications`, { params });
const approveApplication = ({ id }: ApproveAplicationParams) =>
  client.post(`/api/scholars/scholarships/applications/${id}/approve`);
const rejectApplication = ({ id }: ApproveAplicationParams) =>
  client.post(`/api/scholars/scholarships/applications/${id}/reject`);
const cancelApplication = ({ id }: ApproveAplicationParams) =>
  client.post(`/api/scholars/scholarships/applications/${id}/self-cancel`);
const cancelScholarship = ({ scholarshipId }: CancelScholarshipParams) =>
  client.post(`/api/scholars/scholarships/${scholarshipId}/cancel`);
const applyScholarship = ({ scholarshipId }: CancelScholarshipParams) =>
  client.post(`/api/scholars/scholarships/${scholarshipId}/apply`);
const activateScholarship = ({ scholarshipId }: CancelScholarshipParams) =>
  client.post(`/api/scholars/scholarships/${scholarshipId}/active`);
const generatePassword = ({ scholarshipId }: CancelScholarshipParams): Promise<GeneratePasswordResType> =>
  client.post(`/api/scholars/scholarships/${scholarshipId}/gernerate-password`);
const getSignature = ({ scholarshipId }: CancelScholarshipParams): Promise<CreateSignatureResType> =>
  client.get(`/api/scholars/scholarships/${scholarshipId}/creating-signature`);
const getUnlockSignature = ({ scholarshipId }: CancelScholarshipParams): Promise<CreateSignatureResType> =>
  client.get(`/api/scholars/scholarships/${scholarshipId}/unlock-signature`);
const confirmUnlocked = ({ scholarshipId }: CancelScholarshipParams) =>
  client.post(`/api/scholars/scholarships/${scholarshipId}/confirm-unlocked`);
const getSubAccs = (params: GetSubAccsParams): Promise<SubAccResType> =>
  client.get(`/api/scholars/sub-accs`, { params });
const createSubAcc = (body: CreateSubAccParams) => client.post(`/api/scholars/sub-accs`, body);
const transferGKAB = (body: TransferGKABParams) => client.post(`/api/scholars/scholarships/assets/transfer`, body);
const claimScholarship = (body: ClaimScholarshipParams) => client.post(`/api/scholars/scholarships/claim`, body);
const payoutScholarship = (body: ClaimScholarshipParams) => client.post(`/api/scholars/scholarships/payout`, body);

export default {
  getScholarship,
  getScholarStatus,
  createScholarship,
  getScholarshipById,
  editScholarship,
  getSubAccs,
  createSubAcc,
  getScholarApplication,
  approveApplication,
  rejectApplication,
  cancelApplication,
  cancelScholarship,
  applyScholarship,
  activateScholarship,
  generatePassword,
  getSignature,
  getUnlockSignature,
  confirmUnlocked,
  transferGKAB,
  claimScholarship,
  payoutScholarship,
};
