import { CardMedia, Divider, Tooltip } from '@mui/material';
import { useWindowDimensions } from 'hooks';
import { ScholarshipsHeroType } from 'models/Scholarship';
import { getRankColor } from 'utils/common';

const CardHeroMinimized = ({ item }: { item: ScholarshipsHeroType }) => {
  const { isMobile } = useWindowDimensions();

  const Stats = () => (
    <div className='grid grid-cols-2 gap-x-5 gap-y-2 mb-5'>
      {[
        { icon: 'POW', value: Math.round(item?.power ?? 0) },
        { icon: 'HP', value: Math.round(item.currentStats.hp ?? 0) },
        { icon: 'ATK', value: (item.currentStats.atk ?? 0).toFixed(1) },
        { icon: 'SPD', value: (item.currentStats.attackSpeed ?? 0).toFixed(1) },
        { icon: 'DPS', value: Math.round(item.currentStats.dps ?? 0) },
        { icon: 'BRE', value: Math.round(item?.breed_count ?? 0) },
      ].map((item, index) => (
        <div key={index}>
          <div className='flex gap-1'>
            <img src={require(`assets/games/Stat_${item.icon}.png`).default} className='w-5 h-5' />
            <span className='text-color-secondary'>{item.value}</span>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <Tooltip
      disableInteractive={true}
      componentsProps={{
        tooltip: { className: 'bg-color-dark', style: { border: '1px solid #574239', backdropFilter: 'blur(10px)' } },
        arrow: { className: 'text-color-dark' },
      }}
      title={
        isMobile ? (
          ''
        ) : (
          <div className='text-base text-color-secondary p-5'>
            <div className='flex justify-between mb-3'>
              <div className='text-xl font-black'>{item.name}</div>
              <div className='flex gap-1'>
                <img src={require(`assets/games/Race_${item.race ?? 'None'}.png`).default} className='h-6 w-6' />
                <Divider orientation='vertical' variant='middle' flexItem style={{ border: '0.1px solid #574239' }} />
                <img src={require(`assets/games/Class_${item.class ?? 'None'}.png`).default} className='h-6 w-6' />
              </div>
            </div>
            <div className='flex justify-between text-xs font-extrabold mb-5'>
              <div className={`tracking-extra text-color-${getRankColor((item.rank ?? '').toLowerCase())}`}>
                {item.rank}
              </div>
              <div className='text-color-primary'>{`Lvl. ${item.level}`}</div>
            </div>
            <Divider variant='fullWidth' className='mb-5' style={{ borderBottom: '1px solid #574239' }} />
            <Stats />
            <Divider variant='fullWidth' className='mb-5' style={{ borderBottom: '1px solid #574239' }} />
            <div className='mb-5'>
              <div className='font-skadi'>Skills</div>
              <div className='flex gap-2'>
                {!!item?.skills &&
                  Object.values(item.skills).map((value, index) => (
                    <img key={index} src={value.icon} className='h-20 w-16' />
                  ))}
              </div>
            </div>
            <Divider variant='fullWidth' className='mb-5' style={{ borderBottom: '1px solid #574239' }} />
            <div>
              <div className='font-skadi'>Troops</div>
              <div className='flex gap-2'>
                {!!item?.troops &&
                  Object.values(item?.troops).map((value, index) => (
                    <img key={index} src={value.icon} className='h-20 w-16' />
                  ))}
              </div>
            </div>
          </div>
        )
      }
      placement='right'
    >
      <CardMedia image={item.image} className='h-10 w-10' style={{ border: '1px solid #B7A284', borderRadius: 4 }} />
    </Tooltip>
  );
};

export default CardHeroMinimized;
