import { useEffect, useState } from 'react';

const getBrowser = () => {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge';
  } else {
    browserName = 'No browser detection';
  }
  return browserName;
};

const getOS = () => {
  var OS = 'Unknown';
  if (navigator.userAgent.indexOf('Win') !== -1) OS = 'Windows';
  if (navigator.userAgent.indexOf('Mac') !== -1) OS = 'MacOS';
  if (navigator.userAgent.indexOf('X11') !== -1) OS = 'UNIX';
  if (navigator.userAgent.indexOf('Linux') !== -1) OS = 'Linux';
  return OS;
};

const useOSInfo = () => {
  const [browserName, setBrowserName] = useState('');
  const [operatingSystem, setOperatingSystem] = useState('');

  useEffect(() => {
    setBrowserName(getBrowser());
    setOperatingSystem(getOS());
  }, []);

  return [browserName, operatingSystem] as [string, string];
};

export default useOSInfo;
