import { styled, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { CloseButton, DesignButton } from 'components';
import { useWindowDimensions } from 'hooks';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { profileRoute } from 'routes';
import { customizeFloatNumber, shorten } from 'utils/common';
import { sumBy } from 'utils/sumBy';

const StyledTableCell = styled(TableCell)`
  border-color: #45342c;
  color: #f1e9dc;
  text-align: center;
  background-color: #271911;
  @media (max-width: 800px) {
    font-size: 10px;
    background-color: #170a02cc;
  }
`;

const StyledHeaderCell = styled(TableCell)`
  border-color: #574239;
  color: #b7a284;
  text-align: center;
  font-weight: 900;
  background-color: rgba(23, 10, 2, 0.8);
  @media (max-width: 800px) {
    font-size: 10px;
  }
`;

const PopupPayout = ({
  onClose,
  handleClick,
  maxLimit,
  selectedScholarship,
}: {
  onClose: () => void;
  handleClick: () => void;
  maxLimit: number;
  selectedScholarship: any;
}) => {
  const { isMobile } = useWindowDimensions();

  const totalPay = useMemo(() => sumBy('payToScholar', selectedScholarship), [selectedScholarship]);

  const scholarNotRegistered = useMemo(
    () =>
      selectedScholarship
        .map((item: any) => {
          if (!item?.assigneeUserName && !item?.assigneeEmail) {
            return item?.scholarshipName;
          } else {
            return undefined;
          }
        })
        .filter((item: any) => item !== undefined),
    [selectedScholarship],
  );

  const isNotReady = useMemo(
    () => scholarNotRegistered.length > 0 || maxLimit < totalPay || totalPay === 0,
    [maxLimit, scholarNotRegistered.length, totalPay],
  );

  const errorMes = useMemo(() => {
    let mes = '';
    scholarNotRegistered.forEach((item: string, index: number) => {
      mes = `${mes}${item}${index === scholarNotRegistered.length - 1 ? '' : ', '}`;
    });
    return `You may not payout to scholarship ${mes} cause the scholars have not created account yet`;
  }, [scholarNotRegistered]);

  return (
    <div className='px-2 md:px-8 py-10 text-color-secondary' style={{ maxWidth: 520 }}>
      <div className='font-skadi text-xl md:text-3xl mb-4 md:mb-8 text-center'>PAYOUT TO YOUR SCHOLAR</div>
      <div className='flex gap-3 items-center justify-center text-2xl md:text-huge font-extrabold text-color-greenish mb-4 md:mb-8'>
        <img
          src={require(`assets/icons/KAB-token.png`).default}
          style={{ width: isMobile ? 32 : 40, height: isMobile ? 32 : 40 }}
        />
        <div>
          {customizeFloatNumber(totalPay)} <span className='text-base md:text-xl'>gKAB</span>
        </div>
      </div>
      <div className='overflow-auto mb-4' style={{ maxHeight: 305, border: '1px solid #45342C', borderRadius: 8 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledHeaderCell>Scholarship</StyledHeaderCell>
              <StyledHeaderCell>Scholar</StyledHeaderCell>
              <StyledHeaderCell>Amount</StyledHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedScholarship.map((item: any, index: number) => {
              return (
                <TableRow key={index}>
                  <StyledTableCell>{item?.scholarshipName ?? ''}</StyledTableCell>
                  <StyledTableCell>
                    <div className='flex flex-col gap-1'>
                      <Tooltip
                        placement='top'
                        title={<div className='text-lg text-color-primary'>{item?.scholarName}</div>}
                      >
                        <div>{shorten(item?.assigneeUserName ?? '', 15, 0, 15)}</div>
                      </Tooltip>
                      <div className='text-sm' style={{ color: '#94794D' }}>
                        {shorten(item?.scholarWeb3Address ?? '')}
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>{`${item?.payToScholar ?? 0} gKAB`}</StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className='text-center mb-4 text-sm'>
        {scholarNotRegistered.length > 0 && (
          <div className='mb-2' style={{ color: '#FF613F' }}>
            {errorMes}
          </div>
        )}
        {maxLimit < totalPay && (
          <>
            <div className='mb-2' style={{ color: '#FF613F' }}>
              Oops, look like you don't have enough gKAB balance in your main-account.
            </div>
            <Link to={profileRoute.accountOverview.url}>
              <div className='text-color-legendary font-extrabold underline'>Deposit gKAB here</div>
            </Link>
          </>
        )}
      </div>
      <DesignButton
        fullWidth
        design={isNotReady ? 'gray' : 'yellow'}
        size={isMobile ? 'medium' : 'large'}
        onClick={() => {
          if (!isNotReady) {
            handleClick();
            onClose();
          }
        }}
      >
        PAYOUT
      </DesignButton>
      <CloseButton onClick={onClose} />
    </div>
  );
};

export default PopupPayout;
