import { Container } from '@mui/material';
import { useTabs, useWindowDimensions } from 'hooks';
import TableAssignedScholar from './TableAssignedScholar';
import TableScholarship from './TableScholarship';

const Scholar = () => {
  const { isMobile } = useWindowDimensions();
  const tabs = [
    {
      code: 'scholarships',
      label: `Scholarships`,
      component: <TableScholarship />,
    },
    {
      code: 'assigned',
      label: `Assigned`,
      component: <TableAssignedScholar />,
    },
  ];
  const [activeTab, onChangeTab] = useTabs(tabs, 'tab-scholar');

  return (
    <>
      <Container className='text-color-secondary pt-14'>
        <div className='font-skadi text-3xl mb-4'>As a scholar</div>
        <div className='bg-color-browny p-5 flex justify-center md:block' style={{ borderRadius: 10 }}>
          <div className='flex w-fit gap-4 px-4 md:mb-6' style={{ borderRadius: '10px', background: '#3B2B1F' }}>
            {tabs.map((tab, index) => {
              const selected = activeTab === tab.code;

              return (
                <div
                  key={index}
                  className='font-black cursor-pointer px-2 py-3'
                  style={{ borderBottom: selected ? '2px solid #F07300' : '', color: selected ? '#FF9C4A' : '#655547' }}
                  onClick={(event) => onChangeTab(event, tab.code)}
                >
                  {tab.label}
                </div>
              );
            })}
          </div>
          {!isMobile &&
            tabs.map((tab) => (
              <div hidden={tab.code !== activeTab} key={tab.code}>
                {tab.component}
              </div>
            ))}
        </div>
      </Container>
      {isMobile && (
        <div className='mt-3'>
          {tabs.map((tab) => (
            <div hidden={tab.code !== activeTab} key={tab.code}>
              {tab.component}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Scholar;
