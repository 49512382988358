import { CircularProgress, Dialog, styled, TextField } from '@mui/material';
import { DesignButton } from 'components';
import { BinanceHeroRedemptionContract, erc721Contract } from 'contracts';
import { NFT_CONTRACT } from 'env';
import { useWindowDimensions } from 'hooks';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { nftService, queryClient } from 'services';

const PriceField = styled(TextField)`
  box-shadow: 0px -6px #705439;
  border-radius: 10px;
  width: 534px;
  @media only screen and (max-width: 800px) {
    width: 100% !important;
  }
  & .MuiOutlinedInput-root {
    background: #b7a284;
    border-radius: 10px;
  }
  & .MuiOutlinedInput-input {
    font-weight: 800;
    font-size: 20px;
    color: #463024;
    padding: 11px;
    @media (max-width: 800px) {
      font-size: 12px;
      text-align: center;
      padding: 6px;
    }
  }
`;

const Setting = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { address } = useSelector(profileSelector);
  const { isMobile } = useWindowDimensions();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const [openPopupSuccess, setOpenPopupSuccess] = useState(false);

  const { mutate: redeem, isLoading } = useMutation(
    async (bHeroTokenId: string) => {
      const redeemInfo = await nftService.redeemHero({ bHeroTokenId });
      const isApprovedForAll = await erc721Contract(NFT_CONTRACT!)
        .methods.isApprovedForAll(address, redeemInfo.contractAddr)
        .call();
      if (!isApprovedForAll) {
        await erc721Contract(NFT_CONTRACT!)
          .methods.setApprovalForAll(redeemInfo.contractAddr, true)
          .send({ from: address });
      }

      await BinanceHeroRedemptionContract(redeemInfo.contractAddr)
        .methods.redeem([redeemInfo.bHeroTokenId], [redeemInfo.heroTokenId], redeemInfo.signature)
        .send({ from: address });
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Redeem hero successfully!', { variant: 'success' });
        queryClient.invalidateQueries('nftService.fetchHeroes');
        setOpenPopupSuccess(true);
      },
      onError: (e: any) => {
        enqueueSnackbar(e.response?.data?.message ?? 'Redeem hero failed!', { variant: 'error' });
      },
    },
  );

  return (
    <div>
      <div
        className='flex flex-col items-center md:items-start md:bg-color-dark text-color-secondary md:px-12 md:py-7 mb-5'
        style={{ borderRadius: 10 }}
      >
        <div className='font-bold text-base md:text-xl mb-2 md:mb-5'>Binance NFTs</div>
        <div className='text-center md:text-left text-xs md:text-base mb-10' style={{ maxWidth: 570 }}>
          Each Binance NFT can be redeemed for a Genesis Hero of the same rarity. Enter the Binance NFT’s TokenID to
          summon your Genesis Hero.
        </div>
        <div className='font-bold text-base md:text-xl mb-2'>Your Binance NFT’s TokenID</div>
        <div className='flex flex-col md:flex-row items-center gap-8 md:gap-6 w-full'>
          <Controller
            name='bHeroTokenId'
            defaultValue=''
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <div className='pt-1.5 w-full md:w-auto'>
                <PriceField
                  {...field}
                  fullWidth
                  variant='outlined'
                  placeholder='Input TokenID'
                  size='medium'
                  error={invalid}
                />
                {invalid && (
                  <div className='text-red-500 text-tiny md:text-sm mt-3'>
                    Token ID is invalid or does not belong to you. Please try again.
                  </div>
                )}
              </div>
            )}
          />
          {!isLoading ? (
            <DesignButton
              fullWidth
              design='yellow'
              size={isMobile ? 'medium' : 'large'}
              className='w-48 md:w-80'
              onClick={() => {
                handleSubmit((values) => {
                  redeem(values.bHeroTokenId);
                })();
              }}
            >
              CLAIM
            </DesignButton>
          ) : (
            <DesignButton fullWidth design='yellow' size={isMobile ? 'medium' : 'large'} className='w-48 md:w-80'>
              <CircularProgress color='inherit' className='w-5 h-5 mr-1.5' /> PROCESSING
            </DesignButton>
          )}
        </div>
      </div>
      <Dialog maxWidth='md' open={openPopupSuccess}>
        <div className='flex flex-col items-center py-5 md:p-14'>
          <img src={require('assets/icons/icon-success.png').default} className='mb-7 md:mb-8 w-20 md:w-32' />
          <div className='text-white font-skadi text-xl md:text-3xl text-center mb-2.5'>NFT CLAIMED SUCCESSFULLY!</div>
          <div className='text-xs md:text-xl mb-8 md:mb-9 text-center' style={{ maxWidth: 539 }}>
            Well done Chieftain! You claimed your Binance NFT. The new-born Hero will join your ranks in 5-10 minutes
          </div>
          <DesignButton
            fullWidth
            design='yellow'
            size={isMobile ? 'medium' : 'large'}
            className='w-48 md:w-96'
            onClick={() => setOpenPopupSuccess(false)}
          >
            CONFIRM
          </DesignButton>
        </div>
      </Dialog>
    </div>
  );
};

export default Setting;
