export const termOfUse = [
  {
    title: '1. Acceptance of Terms',
    text: (
      <div>
        The Ookeenga is a 3D gaming project which built on the Binance Smart Chain. With breath-taking graphics,
        appealing world-building, and addictive gameplay, Ookeenga is ready to create an unique- ly immersive
        play-own-earn experience. Ookeenga holds the intellectual property rights over, and makes (a) many features,
        tools and/or materials made available from time to time by the Ookeenga, are referred to herein as the "Tools"
        and (b) the website https://ookeenga.com/ (the "Site"). Please read these Terms of Use (the "Terms" or "Terms of
        Use") carefully before using the Tools and the Site. By using or otherwise accessing the Tools and the Site,
        you: (1) accept and agree to be bound by these Terms; (2) accept that the Tools are still in testing phase and
        that you use at your own risk, as further explained in Section 2 below; (3) repre- sent that you are old enough
        to use the Tools and the Site pursuant to Sections 3 and 9 be- low; (4) consent to the collection, use,
        disclosure and other handling of information as de- scribed in the Privacy Policy, available here
        ookeenga.io/privacy; (5) accept and agree (5.1.) any additional terms and conditions of participation issued by
        the Ookeenga from time to time. If you do not agree to the Terms, then you must not access or use the Tools or
        the Site.
        <br />
        <br />
        As per the endowment received, the Ookeenga makes available the Tools and the Site free of charge in order to
        allow different interactions with the Ookeenga platform ("Ookeenga") The Ookeenga has no continuing obligation
        to operate the Tools and the Site and may cease to operate one or more of the Tools in the future, at its
        exclusive discretion, with no liability whatsoever in connection thereto.
        <br />
        With respect to the source code of the software of the Tools that has been released under an open source
        license, such software code must be used in accordance with the applicable open source license terms and
        conditions as described in Section 13 below. Other similar tools might be developed in the future by the
        community or third parties.
      </div>
    ),
  },
  {
    title: '2. Disclaimer and Modification of Terms of Use',
    text: (
      <div>
        The Tools are provided on an "as is" and "as available" basis and may contain defects and software bugs. You are
        advised to safeguard important data, property and content, to use cau- tion, and not to rely in any way on the
        correct or secure functionality or performance of the Tools.
        <br />
        Except for Section 17, providing for binding arbitration and waiver of class action rights, as detailed in
        Section 7, the Ookeenga reserves the right, to modify or replace the Terms of Use at any time. The most current
        version of these Terms will be posted on the Site. You shall be responsible for reviewing and becoming familiar
        with any such modifications. Use of the Tools by you after any modification to the Terms constitutes your
        acceptance of the Terms of Use as modified.
      </div>
    ),
  },
  {
    title: '3. Eligibility',
    text: (
      <div>
        You hereby represent and warrant that you are fully able and competent to enter into the terms, conditions,
        obligations, affirmations, representations and warranties set forth in these Terms and to abide by and comply
        with these Terms. Ookeenga is a global platform and by accessing the Content, the Site or Tools, you are
        representing and warranting that you are of the legal age of majority in your jurisdiction as is required to
        access such Tools and Content and enter into arrangements as provided by the Tools. You further represent that
        you are oth- erwise legally permitted to use the Tools in your jurisdiction including owning cryptographic
        tokens, and interacting with the Site, Tools or Content in any way. You further represent that you are
        responsible for ensuring compliance with the laws of your jurisdiction and acknowl- edge that the Ookeenga is
        not liable for your compliance or failure to comply with such laws. You further represent and warrant that all
        funds or assets used by you have been law- fully obtained by you in compliance with all applicable laws.
      </div>
    ),
  },
  {
    title: '4. Account Access and Security',
    text: (
      <div>
        Access to the Tools is provided via a third party private key manager selected by you (e.g., a Web3 Provider,
        Metamask, a USB interface for Ledger Wallet, the Mist browser, or other). Security and secure access to each
        account in the Tools is provided solely by the third party private key manager you select to administer your
        private key. You and the third party pri- vate key manager you select are entirely responsible for security
        related to access of the Tools and all information provided by you to such third party provider (including
        without limitation, email or phone number). The Ookeenga bear no responsibility for any breach of security or
        unauthorized access to your account (the "Account"). You are advised to: (a) avoid any use of the same password
        with your selected third party private key manager that you have ever used outside of the third party private
        key manager; and (b) keep your pass- word and any related secret information secure and confidential and do not
        share them with anyone else.
        <br />
        You are solely responsible for maintaining the confidentiality of your password and for re- stricting access to
        your devices. You are solely responsible for any harm resulting from your disclosure, or authorization of the
        disclosure, of your password or from any person's use of your password to gain access to your Account. You
        acknowledge that in the event of any unauthorized use of or access to your Account, password or other breach of
        security, you ac- cept that due to the nature of the Tools and the platform itself, the Ookeenga will be unable
        to remedy any issues that arise.
        <br />
        The Ookeenga will not be liable for any loss or damage (of any kind and under any legal theory) to you or any
        third party arising from your inability or failure for any reason to com- ply with any of the foregoing
        obligations, or for any reason whatsoever, except fraud on our part.
        <br />
        The Ookeenga cannot and will not be liable for any loss or damage arising from your sharing or other loss of
        your private key or related information, or any other damage or loss arising from unauthorized access to your
        Account.
        <br />
        Transactions that take place using the Tools are confirmed and managed via the Binance Smart Chain blockchain.
        You understand that your Binance Smart Chain public address will be made publicly visible whenever you engage in
        a transaction using the Tools.
        <br />
        If you reside in a jurisdiction where online gambling is banned (such as the United States of America, China and
        South Korea) you must refrain from accessing Content which includes online gambling.
      </div>
    ),
  },
  { title: '5. Representations and Risks', text: <div></div> },
  {
    title: '5.1 Disclaimer',
    text: (
      <div>
        You acknowledge and agree that your use of the Tools and the Site is at your sole risk. The Tools and the Site
        are provided on an "AS IS" and "AS AVAILABLE" basis, without war- ranties of any kind, either express or
        implied, including, without limitation, implied war- ranties of merchantability, fitness for a particular
        purpose or non-infringement. You ac- knowledge and agree that the Ookeenga has no obligation to take any action
        regarding: which users gain access to or use the Tools; what effects the Tools may have on you; the LAND you
        own; how you may interpret or use the Tools; or what actions you may take or fail to take as a result of having
        been exposed to the Tools. You release the Ookeenga from all liability for your inability to access to the Site,
        Tools or any Content therein. The Ookeenga is not and cannot be responsible for and makes no representations,
        warranties or covenants concerning any Content contained in or accessed through the Site, Tools, and the
        Ookeenga will not be responsible or liable for the accuracy, copyright compliance, legality or decency of
        material contained in or accessed through the Tools. The Content Policy over Ookeenga is established and
        enforced exclusively by the Ookeenga community through the DAO.
      </div>
    ),
  },
  {
    title: '5.2 Sophistication and Risk of Cryptographic Systems',
    text: (
      <div>
        By utilizing the Tools, the Site or interacting with the Tools or platform or anything con- tained or provided
        therein in any way, you represent that you understand the inherent risks associated with cryptographic systems;
        and warrant that you have an understanding of the usage, risks, potential bugs based on novel technology (where
        applicable), and intricacies of native cryptographic tokens, smart contract based tokens and blockchain-based
        software sys- tems.
      </div>
    ),
  },
  {
    title: '5.3 Risk of Regulatory Actions in One or More Jurisdictions',
    text: (
      <div>
        The Ookeenga could be impacted by one or more regulatory inquiries or regulatory action, which could impede or
        limit your ability to access or use the Tools or Binance Smart Chain blockchain.
      </div>
    ),
  },
  {
    title: '5.4 Risk of Weaknesses or Exploits in the Field of Cryptography',
    text: (
      <div>
        You acknowledge and agree that cryptography is a progressing field. Advances in code cracking or technical
        advances such as the development of quantum computers may present risks to smart contracts, cryptocurrencies and
        the Tools, which could result in the theft or loss of your cryptographic tokens or property, among other
        potential consequences. By using the Tools you acknowledge and agree to undertake these risks.
      </div>
    ),
  },
  {
    title: '5.5 Use of Crypto Assets',
    text: (
      <div>
        Some Tools allow the use of BNB, OKG, KAB or other similar blockchain technologies. You acknowledge and agree
        that OKG, KAB, BNB and blockchain technologies and associated assets, and other assets are highly volatile due
        to many factors including but not limited to popularity, adoption, speculation, regulation, technology and
        security risks. You also ac- knowledge and agree that the cost of transacting on such technologies is variable
        and may increase at any time causing impact to any activities taking place on the Binance Smart Chain
        blockchain. The Ookeenga does not invite or make any offer to acquire, purchase, sell, transfer or otherwise
        deal in any crypto asset. Third parties may provide services involving the acquisition, purchase, sale, transfer
        or exchange of crypto-assets; the Ookeenga does not provide any such service and does not undertake any
        liability in connection thereto. You ac- knowledge and agree these risks and represent that the Ookeenga cannot
        be held liable for changes and fluctuations in value or increased costs.
        <br />
        There are risks associated with using an Internet-based currency, including, but not limited to, the risk of
        hardware, software and Internet connections failure or problems, the risk of malicious software introduction,
        and the risk that third parties may obtain unauthorized ac- cess to information stored within your wallet. You
        accept and acknowledge that the Ookeen- ga will not be responsible for any communication failures, disruptions,
        errors, distortions or delays you may experience when using the Binance Smart Chain network or any sidechain or
        similar device for processing transactions, however caused.
      </div>
    ),
  },
  {
    title: '5.6 Application Security',
    text: (
      <div>
        You acknowledge and agree that the Site, the Tools and related applications are software code and are subject to
        flaws and acknowledge that you are solely responsible for evaluating any smart contract, code provided by the
        Site, Tools or Content and the trustworthiness of any third-party websites, products, smart-contracts, or
        Content you access or use through the Tools. You further expressly acknowledge and agree that Binance Smart
        Chain applications can be written maliciously or negligently, that the Ookeenga cannot be held liable for your
        interaction with such applications and that such applications may cause the loss of property or even identity.
        This warning and others later provided by the Ookeenga in no way evidence or represent an on-going duty to alert
        you to all of the potential risks of utilizing the Tools or the Site.
      </div>
    ),
  },
  {
    title: '5.7 Third Party Providers',
    text: (
      <div>
        The Ookeenga, neither own nor control MetaMask, Ledger Wallet, the Mist browser, Google Chrome, the Binance
        Smart Chain network, any Web3 Provider or any other third party site, product, or service that you might access,
        visit, or use for the purpose of enabling you to use the various features of the Tools. The Ookeenga shall not
        be liable for the acts or omissions of any such third parties, nor shall the Ookeenga be liable for any damage
        that you may suf- fer as a result of your transactions or any other interaction with any such third parties.
      </div>
    ),
  },
  {
    title: '5.8 Uses of the Tools',
    text: (
      <div>
        You acknowledge and agree that the Tools do not store, send, or receive NFT parcels. This is because NFT parcels
        exist only by virtue of the ownership record maintained on the Tools' supporting blockchain in the Binance Smart
        Chain network. Any transfer of a NFT parcel occurs within the supporting blockchain in the Binance Smart Chain
        network, and not within the Tools.
      </div>
    ),
  },
  {
    title: '5.9 Risks of Changes to Binance Smart Chain Platform',
    text: (
      <div>
        Upgrades by Binance Smart Chain to the Binance Smart Chain platform, a hard fork in the Binance Smart Chain
        platform, or a change in how transactions are confirmed on the Bi- nance Smart Chain platform may have
        unintended, adverse effects on all blockchains using the BEP-20 standard, BEP-721 standard, or any other future
        Binance Smart Chain standard.
      </div>
    ),
  },
  {
    title: '6. Transactions and Feeds',
    text: (
      <div>
        6.1 If you elect to purchase, trade, or sell a non-fungible token ("NFT") with or from other users via the
        Tools, any financial transaction that you engage in will be conducted solely through the Binance Smart Chain
        network, Layer 2 solutions or other sidechains via Web3 providers. The Ookeenga will have no insight into or
        control over these payments or transac- tions, nor does it have the ability to reverse any transactions. With
        that in mind, the Ookeen- ga will have no liability to you or to any third party for any claims or damages that
        may arise as a result of any transactions that you engage in via the Tools, or any other transactions that you
        conduct via the Binance Smart Chain network, and other networks, sidechains, Layer 2 solutions or Web3
        providers.
        <br />
        6.2 Binance Smart Chain requires the payment of a transaction fee (a "Gas Fee") for every transaction that
        occurs on the Binance Smart Chain network. The Gas Fee funds the network of computers that run the decentralized
        Binance Smart Chain network. This means that you will need to pay a Gas Fee for each transaction that occurs via
        the Tools. You accept that the payment of the Gas Fee is inherent to the nature of the Binance Smart Chain
        network and alien to the Ookeenga or due to the use of the Tools.
        <br />
        6.3 Transactions taking place in the Marketplace will be subject to a 4.88% OKG cost (the "Marketplace
        Transaction Cost"). Please be aware that transactions in third-party NFT mar- ketplaces (inside or outside
        Ookeenga) may be subject to the fees charged by said third-party marketplaces as per the respective terms and
        conditions. The Ookeenga shall have no liabili- ty whatsoever with regards to transactions conducted in
        third-party marketplaces.
        <br />
        6.4). Except for income taxes levied on the Ookeenga, you: (i) will pay or reimburse the Ookeenga for all
        national, federal, state, local or other taxes and assessments of any jurisdic- tion, including value added
        taxes and taxes as required by international tax treaties, customs or other import or export taxes, and amounts
        levied in lieu thereof based on charges set, ser- vices performed or payments made hereunder, as are now or
        hereafter may be imposed under the authority of any national, state, local or any other taxing jurisdiction; and
        (ii) shall not be entitled to deduct the amount of any such taxes, duties or assessments from payments made to
        the Ookeenga pursuant to these Terms.
      </div>
    ),
  },
  {
    title: '7. Changes',
    text: (
      <div>
        The Ookeenga approves changes, the Ookeenga will make available the updated Terms through the Site and update
        the "Last Updated" date at the beginning of these Terms accord- ingly. Please check these Terms periodically for
        changes. Any changes to the Terms will ap- ply on the date that they are made, and your continued access to or
        use of the Tools or the Site after the Terms have been updated will constitute your binding acceptance of the
        up- dates. If you do not agree to any revised Terms, you must not access or use the Tools or the Site.
      </div>
    ),
  },
  {
    title: '8. Children',
    text: (
      <div>
        You affirm that you are over the age of 13, as the Tools are not intended for children under 13. IF YOU ARE 13
        OR OLDER BUT UNDER THE AGE OF 18, OR THE LEGAL AGE OF MAJORITY WHERE YOU RESIDE IF THAT JURISDICTION HAS AN
        OLDER AGE OF MAJORITY, THEN YOU AGREE TO REVIEW THESE TERMS WITH YOUR PARENT OR GUARDIAN TO MAKE SURE THAT BOTH
        YOU AND YOUR PARENT OR GUARDIAN UNDERSTAND AND AGREE TO THESE TERMS. YOU AGREE TO HAVE YOUR PAR- ENT OR GUARDIAN
        REVIEW AND ACCEPT THESE TERMS ON YOUR BEHALF. IF YOU ARE A PARENT OR GUARDIAN AGREEING TO THE TERMS FOR THE
        BENEFIT OF A CHILD OVER 13, THEN YOU AGREE TO AND ACCEPT FULL RESPONSIBILITY FOR THAT CHILD'S USE OF THE TOOLS
        OR THE SITE, INCLUDING ALL FINANCIAL CHARGES AND LEGAL LIABILITY THAT HE OR SHE MAY INCUR.
        <br />
        TO ACCESS CERTAIN CONTENTS, YOU WILL NEED TO HAVE A MINIMUM AGE AS DETAILED IN THE CONTENT POLICY.
        <br />
        IN ALL CASES INVOLVING ONLINE GAMBLING YOU MUST BE OLDER THAN 18 YEARS (OR THE MINIMUM LEGAL AGE IN YOUR PLACE
        OF RESIDENCE).
      </div>
    ),
  },
  {
    title: '9. Indemnity',
    text: (
      <div>
        You shall release and indemnify, defend and hold harmless the Ookeenga and its officers, directors, employees
        and representatives from and against any and all losses, liabilities, ex- penses, damages, costs (including
        attorneys' fees and court costs) claims or actions of any kind whatsoever arising or resulting from your use of
        the Tools or the Site, your violation of these Terms of Use, and any of your acts or omissions. The Ookeenga
        reserves the right, at its own expense, to assume exclusive defense and control of any matter otherwise subject
        to indemnification by you and, in such case, you agree to cooperate with the Ookeenga in the defense of such
        matter.
      </div>
    ),
  },
  {
    title: '10. Disclaimers',
    text: (
      <div>
        10.1 YOU ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILI- TY FOR YOUR USE OF THE SITE AND TOOLS. YOU
        ACKNOWLEDGE AND AGREE THAT ANY INFORMATION YOU SEND OR RECEIVE DURING YOUR USE OF THE SITE AND TOOLS MAY NOT BE
        SECURE AND MAY BE INTERCEPTED OR LATER ACQUIRED BY UNAUTHORIZED PARTIES. YOU ACKNOWLEDGE AND AGREE THAT YOUR USE
        OF THE SITE AND TOOLS IS AT YOUR OWN RISK. YOU AC- KNOWLEDGE AND AGREE THAT THE TOOLS ARE PROVIDED "AS IS" AND
        "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IM- PLIED. RECOGNIZING SUCH, YOU ACKNOWLEDGE
        AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER THE OOKEENGA, ITS DIRECTORS, OFFICERS
        AND EMPLOYEES, THE DAO, NOR ITS SUPPLIERS OR LICENSORS WILL BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT,
        INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY OR OTHER DAMAGES OF ANY KIND, INCLUDING WITHOUT
        LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER TANGIBLE OR INTANGIBLE LOSSES OR ANY OTHER
        DAMAGES BASED ON CONTRACT, TORT, STRICT LIABILITY, INFRINGE- MENT OF INTELLECTUAL PROPERTY OR THEFT OR
        MISAPPROPRIATION OF PROPERTY OR ANY OTHER THEORY (EVEN IF THE OOKEENGA HAD BEEN AD- VISED OF THE POSSIBILITY OF
        SUCH DAMAGES), RESULTING FROM THE SITE OR TOOLS; THE USE OR THE INABILITY TO USE THE SITE OR TOOLS; UNAUTHO-
        RIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; STATE- MENTS, CONTENT OR CONDUCT OF ANY THIRD PARTY
        ON THE SITE OR TOOLS; ANY ACTIONS THE OOKEENGA TAKES OR FAILS TO TAKE AS A RESULT OF COM- MUNICATIONS YOU SEND;
        HUMAN ERRORS; TECHNICAL MALFUNCTIONS; FAIL- URES, INCLUDING PUBLIC UTILITY OR TELEPHONE OUTAGES; OMISSIONS, IN-
        TERRUPTIONS, LATENCY, DELETIONS OR DEFECTS OF ANY DEVICE OR NET- WORK, PROVIDERS, OR SOFTWARE (INCLUDING, BUT
        NOT LIMITED TO, THOSE THAT DO NOT PERMIT PARTICIPATION IN THE TOOLS); ANY INJURY OR DAMAGE TO COMPUTER
        EQUIPMENT; INABILITY TO FULLY ACCESS THE SITE OR TOOLS OR ANY OTHER WEBSITE; THEFT, TAMPERING, DESTRUCTION, OR
        UNAUTHO- RIZED ACCESS TO, IMAGES OR OTHER CONTENT OF ANY KIND; DATA THAT IS PROCESSED LATE OR INCORRECTLY OR IS
        INCOMPLETE OR LOST; TYPOGRAPH- ICAL, PRINTING OR OTHER ERRORS, OR ANY COMBINATION THEREOF; OR ANY OTHER MATTER
        RELATING TO THE SITE OR TOOLS. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE
        LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE
        LIMITATIONS MAY NOT APPLY TO YOU.
        <br />
        10.2 THE OOKEENGA HEREBY EXPRESSLY DISCLAIMS, WAIVES, RELEASES AND RENOUNCES ALL WARRANTIES, EXPRESS OR IMPLIED,
        INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICU- LAR PURPOSE, TITLE, OR
        NON INFRINGEMENT.
        <br />
        10.3 WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, THE OOKEEN- GA, ITS DIRECTORS, OFFICERS, EMPLOYEES AND
        LICENSORS DO NOT REPRE- SENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE TOOLS WILL MEET YOUR
        REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF THE TOOLS WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR,
        (III) USAGE DATA PROVIDED THROUGH THE TOOLS WILL BE ACCURATE, (III) THE TOOLS OR ANY CONTENT, SERVICES, OR
        FEATURES MADE AVAILABLE ON OR THROUGH THE TOOLS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR (IV) THAT
        ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE TOOLS WILL BE SE- CURE.
        <br />
        10.4 YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMA- TION AND DEALING ONLINE OVER THE INTERNET, AND
        AGREE THAT THE OOKEENGA, ITS DIRECTORS, OFFICERS AND EMPLOYEES AND THE DAO HAVE NO LIABILITY OR RESPONSIBILITY
        FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO ITS GROSS NEGLIGENCE.
        <br />
        10.5 THE OOKEENGA, ITS DIRECTORS, OFFICERS AND EMPLOYEES AND THE DAO WILL NOT BE RESPONSIBLE OR LIABLE TO YOU
        FOR ANY LOSSES YOU IN- CUR AS THE RESULT OF YOUR USE OF THE BINANCE SMART CHAIN NETWORK OR THE METAMASK OR ANY
        OTHER ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (A) USER ER-
        ROR, SUCH AS FORGOTTEN PASSWORDS OR INCORRECTLY CONSTRUED SMART CONTRACTS OR OTHER TRANSACTIONS; (B) SERVER
        FAILURE OR DATA LOSS; (C) CORRUPTED WALLET FILES; (D) INTELLECTUAL PROPERTY INFRINGEMENT BY THE USERS; (E)
        UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD PARTIES, INCLUDING BUT NOT LIMITED TO THE USE OF VIRUSES, PHISHING,
        BRUTE- FORCING OR OTHER MEANS OF ATTACK AGAINST THE TOOLS, BINANCE SMART CHAIN NETWORK, OR THE METAMASK OR OTHER
        ELECTRONIC WALLET.
        <br />
        10.6 NFT PARCELS, BEP-721 TOKENS ARE INTANGIBLE DIGITAL ASSETS THAT EX- IST ONLY BY VIRTUE OF THE OWNERSHIP
        RECORD MAINTAINED IN THE BI- NANCE SMART CHAIN NETWORK. ALL SMART CONTRACTS ARE CONDUCTED AND OCCUR ON THE
        DECENTRALIZED LEDGER WITHIN THE BINANCE SMART CHAIN PLATFORM. THE OOKEENGA HAS NO CONTROL OVER AND MAKES NO
        GUARANTEES OR PROMISES WITH RESPECT TO THE OWNERSHIP RECORD OR SMART CONTRACTS.
        <br />
        10.7 OKG IS INTANGIBLE DIGITAL ASSETS THAT EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE
        BINANCE SMART CHAIN NETWORK. ALL SMART CONTRACTS ARE CONDUCTED AND OCCUR ON THE DECENTRAL- IZED LEDGER WITHIN
        THE BINANCE SMART CHAIN PLATFORM. THE OOKEENGA HAS NO CONTROL OVER AND MAKE NO GUARANTEES OR PROMISES WITH RE-
        SPECT TO THE OWNERSHIP RECORD OR SMART CONTRACTS. THE OOKEENGA MAKES NO OFFER OR INVITATION TO ACQUIRE,
        PURCHASE, TRANSFER, SELL OR OTHERWISE DEAL IN OKG.
        <br />
        10.8. THE OOKEENGA IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAIN OR ANY OTHER FEATURES OF THE BINANCE SMART
        CHAIN NETWORK OR THE METAMASK OR OTHER ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS
        OR REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE BINANCE SMART CHAIN
        NETWORK, INCLUDING FORKS, TECHNICAL NODE ISSUES, OR ANY OTH- ER ISSUES HAVING FUND LOSSES AS A RESULT.
        <br />
        10.9. SOME TOOLS, AS THE DESKTOP CLIENT PROVIDES YOU WITH FASTER AC- CESS AND INTERACTION WITH OOKEENGA. THE
        DOWNLOAD AND USE OF THE DESKTOP CLIENT MAY INCLUDE THE DOWNLOAD OF THE CONTENT CREATED BY THE USERS AND
        AVAILABLE AT OOKEENGA TO YOUR DEVICE, WHICH MAY BE ILLEGAL IN YOUR COUNTRY OR OFFENSIVE. THE DAO AND THE
        OOKEENGA ARE NOT LIABLE FOR SUCH CONTENT. CONTENT AVAILABLE IN OOKEENGA MAY REDIRECT YOU TO THIRD-PARTY LINKS
        FOR WHICH THE OOKEENGA ARE NOT RESPONSIBLE. YOU ARE SOLELY RESPONSIBLE FOR ALL DOWNLOADS OF THIRD-PARTY CONTENT
        AND DATA, AND FOR ALL THIRD-PARTY LINKS CLICKED ON. PLEASE NOTE THAT BY CREATING A USER ON THE APP YOU RELEASE
        THE DAO AND THE OOKEENGA FROM ANY AND ALL LIABILITY REGARDING THE ABOVE-MENTIONED CONTENT, DATA OR LINKS AND
        ASSUME THE RISKS DE- SCRIBED ABOVE.
      </div>
    ),
  },
  {
    title: '11. Limitation on Liability',
    text: (
      <div>
        11.1 YOU UNDERSTAND AND AGREE THAT THE OOKEENGA, ITS OFFICERS, EM- PLOYEES, DIRECTORS, AND LICENSORS WILL NOT BE
        LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSE- QUENTIAL, OR EXEMPLARY
        DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMI-
        TATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECT- LY), LOSS OF GOODWILL OR BUSINESS
        REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTAN- GIBLE LOSS,
        EVEN IF THE OOKEENGA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        <br />
        11.2 YOU ACKNOWLEDGE AND AGREE THAT THE OOKEENGA HAS MADE THE TOOLS AVAILABLE TO YOU AND ENTERED INTO THESE
        TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A
        REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN THE PARTIES AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN
        US. THE OOKEENGA WOULD NOT BE ABLE TO PROVIDE THE TOOLS TO YOU WITHOUT THESE LIMITATIONS.
        <br />
        11.3 SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, AND
        SOME JURISDICTIONS ALSO LIMIT DISCLAIMERS OR LIMITATIONS OF LIABILITY FOR PERSONAL INJURY FROM CONSUMER
        PRODUCTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO PERSONAL INJURY CLAIMS.
      </div>
    ),
  },
  {
    title: '12. Proprietary Rights',
    text: (
      <div>
        12.1 All title, ownership and Intellectual Property Rights in and to the Site and the Tools are owned
        exclusively by the Ookeenga or its licensors. The Ookeenga holds these Intellectual Property Rights for the
        benefit of the Ookeenga community as a whole. You acknowledge and agree that the Site and Tools contains
        proprietary and confidential information that is protected by applicable intellectual property and other laws.
        Except as expressly authorized in Section 13, you agree not to copy, modify, rent, lease, loan, sell,
        distribute, perform, dis- play or create Derivative Works based on the Site and the Tools, in whole or in part.
        The Ookeenga's exclusive ownership shall include all elements of the Site and Tools, and all In- tellectual
        Property Rights therein. The visual interfaces, graphics (including, without limita- tion, all art and drawings
        associated with Tools), design, systems, methods, information, computer code, software, "look and feel",
        organization, compilation of the content, code, data, and all other elements of the Site and the Tools (but
        excluding the Content submitted by Users) (collectively, the " Ookeenga Materials") are owned by the Ookeenga,
        and are pro- tected by copyright, trade dress, patent, and trademark laws, international conventions, other
        relevant intellectual property and proprietary rights, and applicable laws. All the Ookeenga Materials are the
        copyrighted property of the Ookeenga or its licensors, and all trademarks, logos, service marks, and trade names
        contained in the Ookeenga Materials are proprietary to the Ookeenga or its licensors. Except as expressly set
        forth herein, your use of the Site and the Tools does not grant you ownership of or any other rights with
        respect to any con- tent, code, data, or other materials that you may access on or through the Site and the
        Tools. The Ookeenga reserves all rights in and to the Ookeenga Materials not expressly granted to you in the
        Terms. For the sake of clarity, you understand and agree: (i) that any "purchase" of NFT, whether via the Tools
        or otherwise, does not give you any rights or licenses in or to the Ookeenga Materials (including, without
        limitation, the Ookeenga’s copyright in and to the art and drawings associated with the Tools and content
        therein) other than those express- ly contained in these Terms; and (ii) that you do not have the right to
        reproduce, distribute, or otherwise commercialize any elements of the Ookeenga Materials (including, without
        lim- itation, the Ookeenga’s copyright in and to the art and drawings associated with the Tools and content
        therein) in any way without the Ookeenga’s prior written consent in each case, which consent the Ookeenga may
        withhold in its sole and absolute discretion.
        <br />
        12.2 You may choose to submit comments, bug reports, ideas or other feedback about the Site or the Tools,
        including without limitation about how to improve the Tools (collectively, "Feedback"). By submitting any
        Feedback, you agree that the Ookeenga is free to use such Feedback at the Ookeenga’s discretion and without
        additional compensation to you, and to disclose such Feedback to third parties (whether on a non-confidential
        basis, or otherwise). You hereby grant the Ookeenga a perpetual, irrevocable, nonexclusive, worldwide license
        under all rights necessary for the Ookeenga to incorporate and use your Feedback for any purpose.
        <br />
        12.3 You acknowledge and agree that you are responsible for your own conduct while ac- cessing or using the Site
        and the Tools, and for any consequences thereof. You agree to use the Site and the Tools only for purposes that
        are legal, proper and in accordance with these Terms and any applicable laws or regulations. By way of example,
        and not as a limitation, you may not, and may not allow any third party to: (i) send, post, upload, transmit,
        dis- tribute, disseminate or otherwise make available any Content in violation of the Content Pol- icy approved
        by the Ookeenga, including without limitation, Content that infringes the Intel- lectual Property Rights of any
        party and any Content that contains any hate-related or violent content or contains any other material or
        products that violate or encourage conduct that would violate any criminal laws, any other applicable laws, or
        any third party rights; (ii) dis- tribute viruses, worms, defects, Trojan horses, spyware, time bombs,
        cancelbots, corrupted files, hoaxes, or any other items of a destructive or deceptive nature, or that may
        harvest or collect any data or information about other users without their consent; (iii) impersonate an- other
        person (via the use of an email address or otherwise); (iv) use the Site or the Tools to violate the legal
        rights (such as rights of privacy and publicity) of others; (v) engage in, promote, or encourage illegal
        activity (including, without limitation, money laundering); (vi) interfere with other users' enjoyment of the
        Site or the Tools; (vii) exploit the Site or the Tools for any unauthorized commercial purpose; (viii) post or
        transmit unsolicited or unau- thorized advertising, or promotional materials, that are in the nature of "junk
        mail," "spam," "chain letters," "pyramid schemes," or any other similar form of solicitation; (ix) modify,
        adapt, translate, or reverse engineer any portion of the Tools; (x) remove any copyright, trademark or other
        proprietary rights notices contained in or on the Site or the Tools or any part of it; (xi) reformat or frame
        any portion of the Site or the Tools; (xii) stalk, harass, or engage in any sexual, suggestive, lewd,
        lascivious, or otherwise inappropriate conduct with minors on the Site or the Tools; (xiii) use any robot,
        spider, site search/retrieval application, or other device to retrieve or index any portion of the Site, the
        Tools or the Content posted on the Tools, or to collect information about its users for any unauthorized
        purpose; (xiv) use any cheats, hacks, or any other unauthorized techniques or unauthorized third-party software
        to cheat in any competition or game that may be offered on the Tools by other Users, or to otherwise disrupt or
        modify the Tools or the experience of any users on the Tools; (xv) cre- ate user accounts by automated means or
        under false or fraudulent pretenses; (xvi) attempt to gain unauthorized access to any other user's Account,
        password or Content; or (xvii) access or use the Tools for the purpose of creating a product or service that is
        competitive with the Tools.
        <br />
        12.4 The use of Ookeenga logo and trademarks are regulated in the Terms of use
      </div>
    ),
  },
  { title: '13. Open Source License.', text: <div></div> },
  {
    title: '13.1 Grant of Copyright License.',
    text: (
      <div>
        Subject to the terms and conditions of this License, each Contributor hereby grants to you a perpetual,
        worldwide, non-exclusive, no-charge, royalty-free, irrevocable copyright license to reproduce, prepare
        Derivative Works of, publicly display, publicly perform, sublicense, and distribute the Work and such Derivative
        Works in Source or Object form.
      </div>
    ),
  },
  {
    title: '13.2 Grant of Patent License.',
    text: (
      <div>
        Subject to the terms and conditions of this License, each Contributor hereby grants to you a perpetual,
        worldwide, non-exclusive, no-charge, royalty-free, irrevocable (except as stated in this section) patent license
        to make, have made, use, offer to sell, sell, import, and otherwise transfer the Work, where such license
        applies only to those patent claims licensable by such Contributor that are necessarily infringed by their
        Contribution(s) alone or by combination of their Contribution(s) with the Work to which such Contribution(s) was
        submitted. If you institute patent litigation against any entity (including a cross-claim or counterclaim in a
        lawsuit) alleging that the Work or a Contribution incorporated within the Work constitutes direct or
        contributory patent infringement, then any patent licenses granted to you under this License for that Work shall
        terminate as of the date such litigation is filed.
      </div>
    ),
  },
  {
    title: '13.3 Redistribution.',
    text: (
      <div>
        You may reproduce and distribute copies of the Work or Derivative Works thereof in any medium, with or without
        modifications, and in Source or Object form, provided that you meet the following conditions: (i) modifications
        to the Work and the Derivative Works thereof shall not infringe the Privacy and Content Policies, nor allow the
        infringement of said policies or of Section 12.3 above, and require any further Contributor to abide by these
        limitations; (ii) any modifications to the Work or the Derivative Works can only take place until six (6) months
        have elapsed since the release of the relevant Work or Derivative Works by the Contributor to the general
        public; (iii) you must give any other recipients of the Work or Derivative Works a copy of this License; (iv)
        you must cause any modified files to carry prominent notices stating that you changed the files; (v) you must
        retain, in the Source form of any Derivative Works that you distribute, all copyright, patent, trademark, and
        attribution notices from the Source form of the Work, excluding those notices that do not pertain to any part of
        the Derivative Works; (vi) if the Work includes a "NOTICE" text file as part of its distribution, then any
        Derivative Works that You distribute must include a readable copy of the attribution notices contained within
        such NOTICE file, excluding those notices that do not pertain to any part of the Derivative Works, in at least
        one of the following places: with- in a NOTICE text file distributed as part of the Derivative Works; within the
        Source form or documentation, if provided along with the Derivative Works; or, within a display generated by the
        Derivative Works, if and wherever such third-party notices normally appear. The con- tents of the NOTICE file
        are for informational purposes only and do not modify the License. You may add your own attribution notices
        within Derivative Works that you distribute, alongside or as an addendum to the NOTICE text from the Work,
        provided that such addi- tional attribution notices cannot be construed as modifying the License.
        <br />
        You may add your own copyright statement to your modifications and may provide addition- al or different license
        terms and conditions for use, reproduction, or distribution of your modifications, or for any such Derivative
        Works as a whole, provided your use, reproduc- tion, and distribution of the Work otherwise complies with the
        conditions stated in this Li- cense.
      </div>
    ),
  },
  {
    title: '13.4 Submission of Contributions.',
    text: (
      <div>
        Unless explicitly stated otherwise, any Contribution intentionally submitted for inclusion in the Work by you to
        the Ookeenga and/or its licensors shall be under the terms and conditions of this License, without any
        additional terms or conditions. Notwithstanding the above, noth- ing herein shall supersede or modify the terms
        of any separate license agreement you may have executed with the Ookeenga and/or its licensors regarding such
        Contributions.
      </div>
    ),
  },
  {
    title: '13.5 Trademarks.',
    text: (
      <div>
        This License does not grant permission to use the trade names, logo, trademarks, service marks, or product names
        of the licensee and/or its licensors, except as provided in the Terms of Use for Ookeenga ́s Logo and Name.
      </div>
    ),
  },
  {
    title: '13.6 Disclaimer of Warranty.',
    text: (
      <div>
        Unless required by applicable law or agreed to in writing, the Ookeenga and/or its licensors provide the Work
        (and each Contributor provides its Contributions) on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
        KIND, either express or implied, including, without limitation, any warranties or conditions of TITLE,
        NON-INFRINGE- MENT, MERCHANTABILITY, or FITNESS FOR A PARTICULAR PURPOSE. You are solely responsible for
        determining the appropriateness of using or redistributing the Work and assume any risks associated with your
        exercise of permissions under this License.
      </div>
    ),
  },
  {
    title: '13.7 Limitation of Liability.',
    text: (
      <div>
        In no event and under no legal theory, whether in tort (including negligence), contract, or otherwise, unless
        required by applicable law (such as deliberate and grossly negligent acts) or agreed to in writing, shall any
        Contributor be liable to you for damages, including any di- rect, indirect, special, incidental, or
        consequential damages of any character arising as a re- sult of this License or out of the use or inability to
        use the Work (including but not limited to damages for loss of goodwill, work stoppage, computer failure or
        malfunction, or any and all other commercial damages or losses), even if such Contributor has been advised of
        the pos- sibility of such damages.
      </div>
    ),
  },
  {
    title: '13.8 Accepting Warranty or Additional Liability.',
    text: (
      <div>
        While redistributing the Work or Derivative Works thereof, you may choose to offer, and charge a fee for,
        acceptance of support, warranty, indemnity, or other liability obligations and/or rights consistent with this
        License. However, in accepting such obligations, you may act only on your own behalf and on your sole
        responsibility, not on behalf of any other Con- tributor, and only if you agree to indemnify, defend, and hold
        each Contributor harmless for any liability incurred by, or claims asserted against, such Contributor by reason
        of your ac- cepting any such warranty or additional liability.
      </div>
    ),
  },
  {
    title: '14. Links',
    text: (
      <div>
        The Site and the Tools provide, or third parties may provide, links to other World Wide Web or accessible sites,
        applications or resources. Because the Ookeenga has no control over such sites, applications and resources, you
        acknowledge and agree that the Ookeenga, its officers, employees are not responsible for the availability of
        such external sites, applications or re- sources, and do not endorse and are not responsible or liable for any
        content, advertising, products or other materials on or available from such sites or resources. You further ac-
        knowledge and agree that the Ookeenga, its officers, employees shall not be responsible or liable, directly or
        indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on
        any such content, goods or services available on or through any such site or resource.
      </div>
    ),
  },
  {
    title: '15. No Third-Party Beneficiaries',
    text: (
      <div>
        You agree that, except as otherwise expressly provided in these Terms, there shall be no third-party
        beneficiaries to the Terms.
      </div>
    ),
  },
  {
    title: '16. Notice and Procedure for Making Claims of Copyright Infringement',
    text: (
      <div>
        If you believe that your Intellectual Property Rights (as this term is defined in the Content Policy) or the
        Intellectual Property Rights of a person on whose behalf you are authorized to act has been infringed, you are
        encouraged to contact the infringing party directly. Addition- ally, you may also contact the Ookeenga providing
        the following information:
        <br />
        ● an electronic or physical signature of the person authorized to act on behalf of the owner of the Intellectual
        Property Right;
        <br />
        ● a description of the Intellectual Property Right that you claim has been infringed;
        <br />
        ● description of where the material that you claim is infringing is located on the Tools;
        <br />
        ● your address, and email address;
        <br />
        ● a statement by you that you have a good faith belief that the disputed use is not authorized by the owner of
        the Intellectual Property Right, its agent, or the law;
        <br />
        ● a statement by you, made under penalty of perjury, that the above information in your No- tice is accurate and
        that you are Intellectual Property owner or authorized to act on the own- er's behalf.
        <br />
        The Ookeenga can be reached at email: support@ookeenga.io
        <br />
        To the extent possible, the Ookeenga may try to reach the would-be infringing party to for- ward your concerns.
        The Ookeenga is not in a position to assess the legal merits of the claims.
        <br />
        As the Ookeenga does not control the Content being uploaded by the users, if the user does not agree with your
        claim and/or does not accept to withdraw the infringing content as per the votes of the community, may take any
        of the following measures, (i) block the infringing Content so as to render it inaccessible through the Tools
        (although it could be still accessible through other clients); (ii) block the infringing user's Account. Any
        further action, claim or remedy against the infringing user must be undertaken by the aggrieved user.
        <br />
        Although not at the core of its role or responsibilities, to the extent technically possible, the Ookeenga may
        also, at its sole discretion, (i) block any kind of Content uploaded by users; (ii) suspend user Accounts;
        and/or (iii) request documents evidencing the right of the con- tributor to use Intellectual Property Rights
        embedded in the Content.
      </div>
    ),
  },
  {
    title: '17. Binding Arbitration and Class Action Waiver',
    text: (
      <div>
        PLEASE READ THIS SECTION CAREFULLY - IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE
        A LAWSUIT IN COURT
      </div>
    ),
  },
  {
    title: '17.1 Initial Dispute Resolution',
    text: (
      <div>
        The parties shall use their best efforts to engage directly to settle any dispute, claim, ques- tion, or
        disagreement and engage in good faith negotiations which shall be a condition to ei- ther party initiating a
        lawsuit or arbitration.
      </div>
    ),
  },
  {
    title: '17.2 Binding Arbitration',
    text: (
      <div>
        If the parties do not reach an agreed upon solution within a period of 30 days from the time informal dispute
        resolution under the Initial Dispute Resolution provision begins, then either party may initiate binding
        arbitration as the sole means to resolve claims, subject to the terms set forth below.
        <br />
        Specifically, any dispute that is not resolved under the Initial Dispute Resolution provision shall be finally
        settled under the Rules of Arbitration of the International Chamber of Com- merce. The following shall apply in
        respect of such arbitration: (i) the number of arbitrators shall be three (one nominated by each party and one
        nominated by the ICC); (ii) the decision of the arbitrators will be binding and enforceable against the parties
        and a judgment upon any award rendered by the arbitrators may be entered in any court having jurisdiction
        thereto (provided that in no event will the arbitrator have the authority to make any award that pro- vides for
        punitive or exemplary damages or to award damages excluded by these Terms or in excess of the limitations
        contained in these Terms); (iii) the seat, or legal place, of arbitra- tion shall be the City of Panama, Panama;
        and (iv) the language to be used in the arbitral proceedings shall be English, any documents submitted as
        evidence that are in another lan- guage must be accompanied by an English translation and the award will be in
        the English language. Claimants and respondents shall bear its or their own costs of the arbitration, in-
        cluding attorney's fees, and share equally the arbitrators' fees and ICC's administrative costs. For purposes of
        cost sharing, all claimants shall be considered one party and all respondents shall be considered one party. The
        parties shall maintain strict confidentiality with respect to all aspects of any arbitration commenced pursuant
        to these Terms and shall not disclose the fact, conduct or outcome of the arbitration to any non-parties or
        non-participants, except to the extent required by applicable Law or to the extent necessary to recognize,
        confirm or en- force the final award or decision in the arbitration, without the prior written consent of all
        parties to the arbitration.
      </div>
    ),
  },
  {
    title: '17.3 Class Action Waiver',
    text: (
      <div>
        The parties further agree that any arbitration shall be conducted in their individual capacities only and not as
        a class action or other representative action, and the parties expressly waive their right to file a class
        action or seek relief on a class basis. YOU AND THE OOKEENGA AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
        ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
        REPRESENTATIVE PROCEEDING. If any court or arbitra- tor determines that the class action waiver set forth in
        this paragraph is void or unenforce- able for any reason or that an arbitration can proceed on a class basis,
        then the arbitration provision set forth above shall be deemed null and void in its entirety and the parties
        shall be deemed to have not agreed to arbitrate disputes.
        <br />
        4-exception-litigation of Intellectual Property and Small Court Claims
        <br />
        Notwithstanding the parties' decision to resolve all disputes through arbitration, either party may bring an
        action in state or federal court to protect its Intellectual Property Rights. Either party may also seek relief
        in a small claims court for disputes or claims within the scope of that court's jurisdiction.
      </div>
    ),
  },
  {
    title: '17.5 30-day Right to Opt-Out',
    text: (
      <div>
        You have the right to opt-out and not be bound by the arbitration and class action waiver provisions set forth
        above by sending written notice of your decision to opt-out to the fol- lowing email address: cs@ookeenga.io.
        Your notice must be sent within 30 days of your first use of the Tools, otherwise you shall be bound to
        arbitrate disputes in accordance with the terms of those paragraphs. If you opt-out of these arbitration
        provisions, the Ookeenga also will not be bound by them.
      </div>
    ),
  },
  {
    title: '17.6 Changes to this Section',
    text: (
      <div>
        The Ookeenga will provide 60-days' notice of any changes to this section. Changes will be- come effective on the
        60th day, and will apply prospectively only to any claims arising after the 60th day.
      </div>
    ),
  },
  { title: '18. General Information', text: <div></div> },
  {
    title: '18.1 Entire Agreement',
    text: (
      <div>
        These Terms (and any additional terms, rules and conditions of participation that the Ookeenga may post on the
        Site or on the Tools) constitute the entire agreement between you and the Ookeenga with respect to the Tools and
        supersedes any prior agreements, oral or written, between you and the Ookeenga. In the event of a conflict
        between these Terms and the additional terms, rules and conditions of participation, the latter will prevail
        over the Terms to the extent of the conflict.
      </div>
    ),
  },
  {
    title: '18.2 Waiver and Severability of Terms',
    text: (
      <div>
        The failure of the Ookeenga to exercise or enforce any right or provision of the Terms shall not constitute a
        waiver of such right or provision. If any provision of the Terms is found by an arbitrator or court of competent
        jurisdiction to be invalid, the parties nevertheless agree that the arbitrator or court should endeavor to give
        effect to the parties' intentions as reflect- ed in the provision, and the other provisions of the Terms remain
        in full force and effect.
      </div>
    ),
  },
  {
    title: '18.3 Statute of Limitations',
    text: (
      <div>
        You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or
        related to the use of the Site, the Tools or the Terms must be filed within one (1) year after such claim or
        cause of action arose or be forever barred.
      </div>
    ),
  },
  {
    title: '18.4 Section Titles',
    text: (
      <div>The section titles in the Terms are for convenience only and have no legal or contractual ef- fect.</div>
    ),
  },
  {
    title: '18.5 Communications',
    text: (
      <div>
        Users with questions, complaints or claims with respect to the Site or the Tools may contact us using the
        relevant contact information set forth above and at cs@ookeenga.io.
      </div>
    ),
  },
  {
    title: '19. Definitions',
    text: (
      <div>
        The following definitions shall apply only to Section 13 of the Terms:
        <br />
        "Contribution" shall mean any work of authorship, including the original version of the Work and any
        modifications or additions to that Work or Derivative Works thereof, that is inten- tionally submitted to
        Licensor for inclusion in the Work by the copyright owner or by an in- dividual or Legal Entity authorized to
        submit on behalf of the copyright owner. For the pur- poses of this definition, "submitted" means any form of
        electronic, verbal, or written com- munication sent to the Licensor or its representatives, including but not
        limited to communi- cation on electronic mailing lists, source code control systems, and issue tracking systems
        that are managed by, or on behalf of, the Licensor for the purpose of discussing and improv- ing the Work, but
        excluding communication that is conspicuously marked or otherwise des- ignated in writing by the copyright owner
        as "Not a Contribution."
        <br />
        "Contributor" shall mean the Ookeenga and/or any individual or Legal Entity on behalf of whom a Contribution has
        been received by Licensor and subsequently incorporated within the Work.
        <br />
        "Derivative Works" shall mean any work, whether in Source or Object form, that is based on (or derived from) the
        Work and for which the editorial revisions, annotations, elaborations, or other modifications represent, as a
        whole, an original work of authorship. For the purpos- es of this License, Derivative Works shall not include
        works that remain separable from, or merely link (or bind by name) to the interfaces of, the Work and Derivative
        Works thereof. "Legal Entity" shall mean the union of the acting entity and all other entities that control, are
        controlled by, or are under common control with that entity. For the purposes of this defini- tion, "control"
        means (i) the power, direct or indirect, to cause the direction or management of such entity, whether by
        contract or otherwise, or (ii) ownership of fifty percent (50%) or more of the outstanding shares, or (iii)
        beneficial ownership of such entity.
        <br />
        "License" shall mean the terms and conditions for the use, reproduction, and distribution of the open source, as
        defined by Section 13.
        <br />
        "Licensor" shall mean the copyright owner or entity authorized by the copyright owner that is granting the
        License.
        <br />
        "Object" form shall mean any form resulting from mechanical transformation or translation of a Source form,
        including but not limited to compiled object code, generated documenta- tion, and conversions to other media
        types.
        <br />
        "Source" form shall mean the preferred form for making modifications, including but not limited to software
        source code, documentation source, and configuration files.
        <br />
        "Work" shall mean the work of authorship of the Tools, made available under the License, as indicated by a
        copyright notice that is included in or attached to the Work.
      </div>
    ),
  },
  { title: 'Privacy Policy', text: <div></div> },
  {
    title: '1. Introduction',
    text: (
      <div>
        1.1 This Privacy Policy details important information regarding the collection, use and disclosure of User
        information collected on the Site and the Tools. The aim of this Privacy Policy is to help you understand how
        your personal information is used and your choices regarding said use. By using the Site or the Tools, you agree
        that the Ookeenga can collect, use, disclose, and process your information as described in this Privacy Policy.
        This Privacy Policy only applies to the Site and the Tools, and not to any other websites, products or services
        you may be able to access or link to via the Site or the Tools. We encourage you to read the privacy policies of
        any other websites you visit before providing your information to them.
        <br />
        The Site and the Tools may evolve over time, and this Privacy Policy will change to reflect that evolution. If
        changes are made, you will be notified by revising the date at the top of this Privacy Policy. In some cases, if
        significant changes are made, an statement may be placed in the homepage. We encourage you to review this
        Privacy Policy periodically to stay informed.
        <br />
        Some third-party providers may place cookies or pixels - small data files stored on your hard drive or in device
        memory - on your browser or hard drive. Note that this Privacy Policy does not cover the use of cookies or
        pixels by such third parties. Most web browsers are set to accept cookies and pixels by default, but you can
        usually set your browser to remove or reject browser cookies or pixels. If you do choose to remove or reject
        cookies or pixels, however, your ability to use the Site or the Tools might be affected.
        <br />
        1.2 This Privacy Policy should be read in conjunction with the Terms of Use. By accessing the Tools, you are
        consenting to the information collection and use practices described in this Privacy Policy.
        <br />
        1.3 Your use of the Client and any personal information you provide through the Tools remains subject to the
        terms of this Privacy Policy and the Terms of Use, as each may be updated from time to time.
        <br />
        1.4 Any questions, comments or complaints that you might have should be emailed to support@ookeenga.io
      </div>
    ),
  },
  {
    title: '2. Information Collected in the Site and the Tools',
    text: (
      <div>
        The personal information collected from you generally may include:
        <br />
        2.1 Network information regarding transactions, including, among other things, the type of device you use,
        access times, hardware model, operating system and version, and other unique device identifiers.
        <br />
        2.2. Information about plugins you might be using, included but not limited to those related to the management
        of cryptocurrency assets and any information provided by them.
        <br />
        2.3. Your email and wallet address(es).
        <br />
        2.4 The Client requires the highest level of browser permissions that could potentially lead to procurement of
        more personal information. Information on these permissions are used for a limited purpose, and why this is
        necessary, can be found in paragraph 3 below.
        <br />
        2.5 Your interactions with the Site are documented via Segment.io and that information is processed by Google.
      </div>
    ),
  },
  {
    title: '3. The Way your Personal Information is used',
    text: (
      <div>
        3.1 As with nearly all interactions that take place on the World Wide Web, the servers may receive information
        by virtue of your interaction with them, including but not limited to IP Addresses.
        <br />
        3.2 The Client requires full browser permissions that could potentially be used to access additional personal
        information. Such browser permissions are used for an extremely limited technical purpose for allowing the
        Client to properly interact with your browser. No additional information is obtained beyond what is necessary to
        provide the Client. No information received is shared with any third-party except as required for provision of
        the Client.
        <br />
        3.3 Analytics tools used for purposes of monitoring web traffic the tools
        <br />
        3.4 Public blockchains provide transparency into transactions and the Ookeenga is not responsible for preventing
        or managing information broadcasted on a blockchain.
      </div>
    ),
  },
  {
    title: '4. What Is Done With Your Information',
    text: (
      <div>
        4.1 Your information may be used in the following ways:
        <br />
        • To analyze trends for how the Site and Tools are being used;
        <br />
        • To improve the Site and the Tools;
        <br />
        • To help personalize your experience of the Site and the Tools; and
        <br />
        • If you provide your contact information, you may receive technical notices, updates, confirmations, security
        alerts, to provide support to you, to tell you about other products and services that might interest you, or to
        respond to your comments or questions.
        <br />
        4.2 Your information may be shared with third parties who need to access it in order to do work related to the
        Site and the Tools, including doing things like helping make the Site or Client available, or providing
        analytics services. These third parties only access and use your information as necessary to perform their
        functions.
        <br />
        4.3 Aggregations and anonymizations that contain your information may be created in a way that does not directly
        identify you. Those aggregations and anonymizations may be used or shared for a variety of purposes related to
        the Site and the Client.
        <br />
        4.4 Your personal information may be disclosed to agents, businesses, or service providers who process your
        personal information for providing the Site and the Tools to you. The agreements with these service providers
        limit the kinds of information they can use or process and ensure they use reasonable efforts to keep your
        personal information secure.
        <br />
        4.5 The Ookeenga also reserves the right to disclose personal information that it believes, in good faith, is
        appropriate or necessary to enforce the Terms of Use, take precautions against liability or harm, to investigate
        and respond to third-party claims or allegations, to respond to court orders or official requests, to protect
        the security or integrity of the Site or the Tools, and to protect the rights, property, or safety of the
        Ookeenga, the community of users and NFT owners, or others.
        <br />
        4.6 In the event that the Ookeenga is involved in a merger, acquisition, sale, bankruptcy, insolvency,
        reorganization, receivership, assignment for the benefit of creditors, or the application of laws or equitable
        principles affecting creditors’ rights generally, or other change of control, there may be a disclosure of your
        information to another entity related to such event.
      </div>
    ),
  },
  {
    title: '5. Your Choice',
    text: (
      <div>
        Your personal information will be processed in accordance with this Privacy Policy, and as part of that you will
        have limited or no opportunity to otherwise modify how your information is used.
      </div>
    ),
  },
  {
    title: '6. Cookies',
    text: (
      <div>
        The Site and the Tools do not use cookies at this time but they might do so in the future, in which case this
        Privacy Policy shall be updated accordingly.
      </div>
    ),
  },
  {
    title: '7. Information Not Collected',
    text: (
      <div>
        Any other personally-identifiable information about you shall not be collected, unless you give it to the
        Ookeenga directly: by filling out a form, giving written feedback, communicating via third party social media
        sites, or otherwise communicating via the Site, the Tools or any other means.
      </div>
    ),
  },
  {
    title: '8. Information Security',
    text: (
      <div>
        Whilst neither the Ookeenga, nor any other organization, can guarantee the security of information processed
        online, the Ookeenga has appropriate security measures in place to protect your personal information. For
        example, the personal information you provide is stored on computer systems with limited access, encryption, or
        both.
      </div>
    ),
  },
  {
    title: '9. Privacy Rights',
    text: (
      <div>
        9.1 Subject to applicable law, you may have some or all of the following rights in respect of your personal
        information: (i) to obtain a copy of your personal information together with information about how and on what
        basis that personal information is processed; (ii) to rectify inaccurate personal information (including the
        right to have incomplete personal information completed); (iii) to erase your personal information (in limited
        circumstances, where it is no longer necessary in relation to the purposes for which it was collected or
        processed); (iv) to restrict processing of your personal information where: a. the accuracy of the personal
        information is contested; b. the processing is unlawful but you object to the erasure of the personal
        information; or c. we no longer require the personal information but it is still required for the establishment,
        exercise or defense of a legal claim; (v) to challenge processing which we have justified on the basis of a
        legitimate interest (as opposed to your consent, or to perform a contract with you); (vi) to prevent us from
        sending you direct marketing; (vii) to withdraw your consent to our processing of your personal information
        (where that processing is based on your consent); (viii) to object to decisions which are based solely on
        automated processing or profiling; (ix) in addition to the above, you have the right to file a complaint with
        the supervisory authority.
        <br />
        9.2. If you reside in California, you may request certain general information regarding our disclosure of
        personal information to third parties for their direct marketing purposes.
        <br />
        9.3. If you wish to investigate the exercising of any of these rights, please write to us at the following
        address: support@ookeenga.io
      </div>
    ),
  },
  {
    title: '10. Changes and Updates',
    text: (
      <div>
        10.1 This Privacy Policy may be revised periodically and this will be reflected by the “Last update posted” date
        above. Please revisit this page to stay aware of any changes. Your continued use of the Client constitutes your
        agreement to this Privacy Policy and any future revisions.
        <br />
        10.2 Contact Information: support@ookeenga.io
      </div>
    ),
  },
];
