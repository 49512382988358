import { EditOutlined, Refresh } from '@mui/icons-material';
import { Button, Dialog, Divider, styled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { DesignButton } from 'components';
import { useWindowDimensions } from 'hooks';
import { DateTime } from 'luxon';
import { GetWithDrawalHistoryParams, WithDrawalHistoryType } from 'models/Withdrawal';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { store } from 'reducers';
import { profileSelector } from 'reducers/profile';
import { openPopupRegister } from 'reducers/register';
import { queryClient, userService, withdrawalService } from 'services';
import { customizeFloatNumber, getRankColor, heroRankMap } from 'utils/common';
import { PopupChangePassword, PopupDeposit, PopupWithdrawal } from './components';
import { parse } from 'query-string';
import { TokenEnum } from 'services/helper';
import { depositKab, depositOkg } from './helpers';
import { useSnackbar } from 'notistack';

const buttonId = 'withdrawal-history-button-';

const handleRowHover = (rowIndex: any) => {
  let row = document.getElementById(`${buttonId}${rowIndex}`);
  if (!!row) row!.style.display = 'block';
};

const handleRowHoverLeave = (rowIndex: any) => {
  let row = document.getElementById(`${buttonId}${rowIndex}`);
  if (!!row) row!.style.display = 'none';
};

const statusList = [
  { code: 1, text: 'Pending', color: '#FFAC26' },
  { code: 2, text: 'Rejected', color: '#FF0300' },
  { code: 3, text: 'Completed', color: '#6CDD37' },
];

const StyledTableRow = styled(TableRow)`
  & .MuiTableRow-root:hover {
    background-color: rgba(142, 122, 107, 0.2) !important;
  }
`;

const StyledTableCell = styled(TableCell)`
  border-color: #463024;
  color: #ccc3b5;
  @media (max-width: 800px) {
    font-size: 10px;
    background-color: #170a02cc;
  }
`;

const StyledHeaderCell = styled(TableCell)`
  border-color: #463024;
  color: #968469;
  background-color: #170a02;
  @media (max-width: 800px) {
    font-size: 10px;
  }
`;

const StyledHeaderCellMobile = styled(TableCell)`
  color: #968469;
  background-color: #170a02;
  font-size: 12px;
  font-weight: 700;
  border: none !important;
`;

const StyledTableCellMobile = styled(TableCell)`
  border: none !important;
  color: #ccc3b5;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.001);
  font-weight: 800;
`;

const TokenSection = ({
  title,
  image,
  token,
  amount,
  button,
  tokenColor,
}: {
  title: string;
  image: string;
  token: string;
  amount: number;
  button: JSX.Element;
  tokenColor: string;
}) => (
  <div
    className='p-6 rounded-2xl flex flex-col gap-4 w-full'
    style={{ background: '#3F291880', border: '1px solid #574239', boxShadow: '0px 4px 24px #1C0C00' }}
  >
    <div className='text-color-primary font-extrabold'>{title}</div>
    <div className='flex gap-2 break-all w-full'>
      <img src={require(`assets/icons/${image}.png`).default} className='h-8' />
      <div className='font-extrabold text-xl' style={{ color: tokenColor }}>
        {`${Number(customizeFloatNumber(amount)).toLocaleString()} `}
        <span className='text-base'>{token}</span>
      </div>
    </div>
    {button}
  </div>
);

const InfoLine = ({
  name,
  value,
  button,
  handleButton,
}: {
  name: string;
  value: string | undefined;
  button?: JSX.Element | string;
  handleButton?: () => void;
}) => {
  const { registered } = useSelector(profileSelector);

  return (
    <>
      <div className='flex justify-between items-center text-sm md:text-xl mb-2 md:mb-6'>
        <div className='flex gap-5'>
          <div className='w-20 md:w-48'>{name}:</div>
          <div>{registered ? value : '_'}</div>
        </div>
        <div>
          {registered && button && (
            <Button
              variant='outlined'
              className='border-white hover:bg-color-dark text-white rounded-lg'
              onClick={handleButton}
            >
              {button}
            </Button>
          )}
        </div>
      </div>
      <Divider className='mb-5 border-color-brown' />
    </>
  );
};

const MobileRow = ({ history, index }: { history: WithDrawalHistoryType; index: number }) => {
  const event = history.isWithdrawal ? 'Withdraw' : 'Deposit';
  const statusInfo = statusList.find((status) => status.code === history.status);
  // const isRetryable =
  //   Math.floor(Date.now() / 1000) - history.updatedAt > 900 && history.status === 1 && !history.isWithdrawal;

  return (
    <>
      <StyledTableRow
        className='capitalize'
        onMouseEnter={() => handleRowHover(index)}
        onMouseLeave={() => handleRowHoverLeave(index)}
      >
        <StyledTableCellMobile>{event}</StyledTableCellMobile>
        <StyledTableCellMobile className='text-right'>
          {Number(customizeFloatNumber(history.amount)).toLocaleString()}
        </StyledTableCellMobile>
      </StyledTableRow>
      <TableRow>
        <TableCell colSpan={2} className='border-none py-0'>
          <div
            className='px-6 py-2 text-xs text-color-secondary flex flex-col gap-2'
            style={{ backgroundColor: '#1C110C' }}
          >
            <div className='flex justify-between'>
              <div className='text-color-primary font-extrabold'>Time(UTC)</div>
              <div>{DateTime.fromSeconds(history.createdAt, { zone: 'utc' }).toFormat('MM/dd/yyyy, HH:mm')}</div>
            </div>
            <div className='flex justify-between'>
              <div className='text-color-primary font-extrabold'>Status</div>
              <div style={{ color: statusInfo?.color }}>{statusInfo?.text ?? ''}</div>
            </div>
            {/* {isRetryable && button} */}
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

const Title = ({ value }: { value: string }) => (
  <div className='font-bold text-xl md:text-2xl mb-3 md:mb-7'>{value}</div>
);

const AccountOverview = () => {
  const { isMobile } = useWindowDimensions();
  const { tokens, registered, address } = useSelector(profileSelector);
  const { search } = useLocation();
  const { owner } = parse(search);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenPopupDeposit, setIsOpenPopupDeposit] = useState(false);
  const [isOpenPopupWithdrawal, setIsOpenPopupWithdrawal] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [gameToken, setGameToken] = useState<TokenEnum | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { data: datagKAB } = useQuery(['withdrawalService.getBalancegKAB'], () =>
    withdrawalService.getBalance({ token: TokenEnum.GKAB }),
  );

  const { data: datagOKG } = useQuery(['withdrawalService.getBalancegOKG'], () =>
    withdrawalService.getBalance({ token: TokenEnum.GOKG }),
  );

  const { data: histories, refetch } = useQuery(
    ['withdrawalService.getWithdrawalHistories', { limit: 200, offset: 0, token: TokenEnum.ALL }],
    ({ queryKey }) => withdrawalService.getWithdrawalHistories(queryKey[1] as GetWithDrawalHistoryParams),
  );

  const { data: info } = useQuery(
    ['userService.fetchUserInfo', { address }],
    ({ queryKey }) => {
      const { address } = queryKey[1] as any;
      return userService.fetchUserInfo(address ?? '');
    },
    {
      enabled: !!address,
    },
  );

  const onConfirmDeposit = async (amount: number, token: TokenEnum) => {
    try {
      setIsLoading(true);
      switch (token) {
        case TokenEnum.KAB:
          await depositKab(address, amount);
          queryClient.invalidateQueries('withdrawalService.getBalancegKAB');
          queryClient.invalidateQueries('fetchTokenBalance');
          break;
        case TokenEnum.OKG:
          await depositOkg(address, amount);
          queryClient.invalidateQueries('withdrawalService.getBalancegOKG');
          queryClient.invalidateQueries('fetchTokenBalance');
          break;
      }
      setIsSuccess(true);
      enqueueSnackbar('Deposit successfully!', { variant: 'success' });
      queryClient.invalidateQueries('withdrawalService.getWithdrawalHistories');
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar('Deposit failed!', { variant: 'error' });
    }
    setIsLoading(false);
  };

  const filterHistory = useMemo(
    () =>
      histories?.items?.filter(
        (item) => !(DateTime.now().toSeconds() - item.createdAt > 1800 && item.status === 1 && !item.isWithdrawal),
      ) ?? [],
    [histories?.items],
  );

  const { data: statistic } = useQuery(
    ['userService.fetchUserStatistic', { owner, address }],
    ({ queryKey }) => {
      const { owner, address } = queryKey[1] as any;
      return userService.fetchUserStatistic((owner as string) ?? address ?? '');
    },
    {
      enabled: !!address || !!owner,
    },
  );

  // const { mutate: retryDeposit, isLoading } = useMutation(withdrawalService.confirmWithdrawalDeposit, {
  //   onSuccess: () => {
  //     enqueueSnackbar('Retry deposit successfully!', { variant: 'success' });
  //     queryClient.invalidateQueries('withdrawalService.getWithdrawalHistories');
  //     queryClient.invalidateQueries('withdrawalService.getGKAB');
  //     queryClient.invalidateQueries('fetchTokenBalance');
  //   },
  //   onError: (e: any) => {
  //     enqueueSnackbar(e.response?.data?.message ?? 'Retry deposit failed!', { variant: 'error' });
  //   },
  // });
  return (
    <div>
      <div className='md:hidden text-color-secondary font-bold text-xl text-center mb-4'>Account Overview</div>
      {!registered && (
        <div
          className='flex flex-col items-center md:items-start text-color-secondary px-3 md:px-12 py-2 md:py-5 mb-4'
          style={{
            background: `url(${require('assets/images/background-register.png').default}) no-repeat top center / cover`,
            borderRadius: 10,
          }}
        >
          <div className='text-white font-skadi text-xl md:text-3xl'>YOU ARE ALMOST THERE!</div>
          <div className='text-sm md:text-xl text-center md:text-left mb-2 md:mb-5'>
            Create and link your game account to fully explore the marketplace
          </div>
          <DesignButton
            design='yellow'
            size={isMobile ? 'medium' : 'large'}
            className='w-64 md:mb-2.5'
            onClick={() => store.dispatch(openPopupRegister())}
          >
            CREATE ACCOUNT
          </DesignButton>
        </div>
      )}
      <div className='bg-color-dark rounded-lg text-color-secondary px-3 md:px-12 py-3 md:py-7 mb-7'>
        <Title value='ACCOUNT' />
        <InfoLine name='Username' value={info?.username} />
        <InfoLine name='Email' value={info?.email} />
        <InfoLine
          name='Password'
          value='**********'
          button={
            <div className='flex items-center font-extrabold gap-2'>
              <EditOutlined sx={{ fontSize: 15 }} /> Edit
            </div>
          }
          handleButton={() => setOpenPopup(true)}
        />
      </div>
      <div className='bg-color-dark px-4 py-6 md:p-14 text-color-secondary mb-4 md:mb-7' style={{ borderRadius: 10 }}>
        <Title value='CRYPTO WALLET' />
        <div className='flex flex-col md:grid md:grid-cols-2 gap-10 mb-14'>
          <TokenSection
            title='Governance Token'
            image='OKG-token'
            token='OKG'
            amount={Number(tokens?.OKG ?? '0') / 1e18}
            button={
              <Button
                variant='contained'
                fullWidth
                onClick={() => {
                  window.open(
                    'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x7758a52c1Bb823d02878B67aD87B6bA37a0CDbF5',
                    '_blank',
                  );
                }}
                className='font-extrabold rounded-lg'
              >
                Get OKG
              </Button>
            }
            tokenColor='#FA8915'
          />
          <TokenSection
            title='Payment Token'
            image='KAB-token'
            token='KAB'
            amount={Number(tokens?.KAB ?? '0') / 1e18}
            button={
              <Button
                variant='contained'
                fullWidth
                onClick={() => {
                  window.open(
                    'https://pancakeswap.finance/swap?outputCurrency=0x2c83d3786fa3c068592aa2dcac650f10d4bbba1d&chainId=56&inputCurrency=0x7758a52c1Bb823d02878B67aD87B6bA37a0CDbF5',
                    '_blank',
                  );
                }}
                className='font-extrabold rounded-lg'
              >
                Get KAB
              </Button>
            }
            tokenColor='#3DBC42'
          />
        </div>
        <Title value='GAME ACCOUNT BALANCE' />
        <div className='md:grid md:grid-cols-2 gap-10 mb-14'>
          <TokenSection
            title='Governance Token'
            image='OKG-token'
            token='gOKG'
            amount={datagOKG ?? 0}
            button={
              <div className='grid grid-cols-2 gap-4'>
                <Button
                  variant='contained'
                  fullWidth
                  className='font-extrabold rounded-lg'
                  onClick={() => {
                    setGameToken(TokenEnum.OKG);
                    setIsSuccess(false);
                    setIsOpenPopupDeposit(true);
                  }}
                >
                  Deposit
                </Button>
                <Button
                  variant='contained'
                  fullWidth
                  className='font-extrabold rounded-lg'
                  onClick={() => {
                    setGameToken(TokenEnum.OKG);
                    setIsSuccess(false);
                    setIsOpenPopupWithdrawal(true);
                  }}
                >
                  Withdraw
                </Button>
              </div>
            }
            tokenColor='#FA8915'
          />
          <TokenSection
            title='Payment Token'
            image='KAB-token'
            token='gKAB'
            amount={datagKAB ?? 0}
            button={
              <div className='grid grid-cols-2 gap-4'>
                <Button
                  variant='contained'
                  fullWidth
                  className='font-extrabold rounded-lg'
                  onClick={() => {
                    setGameToken(TokenEnum.KAB);
                    setIsSuccess(false);
                    setIsOpenPopupDeposit(true);
                  }}
                >
                  Deposit
                </Button>
                <Button
                  variant='contained'
                  fullWidth
                  className='font-extrabold rounded-lg'
                  onClick={() => {
                    setGameToken(TokenEnum.KAB);
                    setIsSuccess(false);
                    setIsOpenPopupWithdrawal(true);
                  }}
                >
                  Withdraw
                </Button>
              </div>
            }
            tokenColor='#3DBC42'
          />
        </div>

        <div className='flex justify-between mb-10'>
          <Title value='HISTORY' />
          <div>
            <Button
              variant='outlined'
              className='text-color-secondary font-extrabold rounded-lg'
              style={{ border: '1px solid #B7A284', borderRadius: 5 }}
              startIcon={<Refresh />}
              onClick={() => {
                refetch();
                queryClient.invalidateQueries('withdrawalService.getBalance');
                queryClient.invalidateQueries('fetchTokenBalance');
              }}
            >
              Refresh
            </Button>
          </div>
        </div>
        <div className='overflow-auto' style={{ maxHeight: 485 }}>
          {isMobile ? (
            <Table>
              <TableHead>
                <TableRow>
                  <StyledHeaderCellMobile>Event</StyledHeaderCellMobile>
                  <StyledHeaderCellMobile className='text-right'>Amount</StyledHeaderCellMobile>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterHistory.map((history, index) => (
                  <MobileRow history={history} index={index} key={index} />
                ))}
              </TableBody>
            </Table>
          ) : (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledHeaderCell>Event</StyledHeaderCell>
                  <StyledHeaderCell>Amount</StyledHeaderCell>
                  <StyledHeaderCell>Token</StyledHeaderCell>
                  <StyledHeaderCell>Time (UTC)</StyledHeaderCell>
                  <StyledHeaderCell>Status</StyledHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterHistory.map((history, index) => {
                  const event = history.isWithdrawal ? 'Withdraw' : 'Deposit';
                  const statusInfo = statusList.find((status) => status.code === history.status);
                  // const isRetryable =
                  //   Math.floor(Date.now() / 1000) - history.updatedAt > 900 &&
                  //   history.status === 1 &&
                  //   !history.isWithdrawal;

                  return (
                    <StyledTableRow
                      key={index}
                      className='capitalize'
                      // onMouseEnter={() => handleRowHover(index)}
                      // onMouseLeave={() => handleRowHoverLeave(index)}
                    >
                      <StyledTableCell>{event}</StyledTableCell>
                      <StyledTableCell>{Number(customizeFloatNumber(history.amount)).toLocaleString()}</StyledTableCell>
                      <StyledTableCell>{history.tokenName ? history.tokenName.toUpperCase() : 'KAB'}</StyledTableCell>
                      <StyledTableCell>
                        {DateTime.fromSeconds(history.createdAt, { zone: 'utc' }).toFormat('MM/dd/yyyy, HH:mm')}
                      </StyledTableCell>
                      <StyledTableCell style={{ color: statusInfo?.color }}>
                        <div className='relative'>
                          {statusInfo?.text ?? ''}
                          {/* {isRetryable && (
                            <div
                              id={`${buttonId}${index}`}
                              className='absolute top-1/2 left-0'
                              style={{ transform: 'translateY(-50%)', display: 'none' }}
                            >
                              <Button
                                startIcon={<ReplayOutlined />}
                                variant='contained'
                                onClick={() => retryDeposit(history._id)}
                              >
                                Retry
                              </Button>
                            </div>
                          )} */}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
      {/* <div className='bg-color-dark rounded-lg text-xs md:text-xl text-color-secondary px-3 md:px-12 py-3 md:py-7 mb-5'>
        <Title value='NFTs summary' />
        <div>
          <div className='flex items-center md:gap-7'>
            <div
              style={{
                background: `url(${require(`assets/icons/icon-cocoon.png`).default}) no-repeat center/cover`,
                width: 77,
                height: 119,
              }}
            />
            <div className='flex flex-1 flex-col gap-1 md:gap-0'>
              <div>Cocoons owned: {statistic?.cocoonsOwned}</div>
              <div>Cocoons opened: {statistic?.cocoonsOpened}</div>
            </div>
          </div>
          <Divider className='mb-3 border-color-brown' />
          <div className='flex items-center md:gap-7'>
            <div
              style={{
                background: `url(${require(`assets/icons/icon-hero.png`).default}) no-repeat center/cover`,
                width: 77,
                height: 119,
              }}
            />
            <div className='flex flex-1 flex-col gap-2 md:gap-4'>
              <div>Hero owned: {statistic?.heroesOwned}</div>
              <div className='flex flex-wrap gap-x-1 md:gap-x-5'>
                <div className='w-10 md:w-11'>Rarity</div>
                {heroRankMap.map((rank, index) => {
                  const rankData = statistic?.heroRanks?.find((item) => item.type === rank);

                  return rankData ? (
                    <div key={index} className='w-20 md:w-36'>
                      <span className={`text-color-${getRankColor(rankData.type?.toLowerCase())}`}>
                        {rankData.type}
                      </span>
                      : {rankData.count}
                    </div>
                  ) : (
                    <div key={index} />
                  );
                })}
              </div>
              <div className='flex flex-wrap gap-x-1 md:gap-x-5'>
                <div className='w-10 md:w-11'>Race</div>
                {statistic?.heroRaces.map((race, index) => (
                  <div key={index} className='w-20 md:w-36'>{`${race.type}: ${race.count}`}</div>
                ))}
              </div>
              <div className='flex flex-wrap gap-x-1 md:gap-x-5'>
                <div className='w-10 md:w-11'>Class</div>
                {statistic?.heroClasses.map((heroClass, index) => (
                  <div key={index} className='w-20 md:w-36'>{`${heroClass.type}: ${heroClass.count}`}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {isOpenPopupDeposit && !!gameToken && (
        <Dialog maxWidth='md' open={isOpenPopupDeposit}>
          <PopupDeposit
            isLoading={isLoading}
            isSuccess={isSuccess}
            gameToken={gameToken}
            maxAmount={
              gameToken === TokenEnum.KAB ? Number(tokens?.KAB ?? '0') / 1e18 : Number(tokens?.OKG ?? '0') / 1e18
            }
            onConfirm={onConfirmDeposit}
            onClose={() => {
              setIsOpenPopupDeposit(false);
            }}
          />
        </Dialog>
      )}
      {isOpenPopupWithdrawal && !!gameToken && (
        <Dialog maxWidth='md' open={isOpenPopupWithdrawal}>
          <PopupWithdrawal
            maxAmount={gameToken === TokenEnum.KAB ? datagKAB : datagOKG}
            gameToken={gameToken}
            onClose={() => {
              setIsOpenPopupWithdrawal(false);
            }}
          />
        </Dialog>
      )}
      <Dialog maxWidth='md' open={openPopup}>
        <PopupChangePassword onSuccess={() => {}} onClose={() => setOpenPopup(false)} />
      </Dialog>
    </div>
  );
};

export default AccountOverview;
