import { CloseButton, DesignButton, StyledCheckBox } from 'components';
import { useWindowDimensions } from 'hooks';
import { ScholarshipsType } from 'models/Scholarship';
import { useState } from 'react';

const PopupCancelScholarship = ({
  onClose,
  handleClick,
  scholarship,
}: {
  onClose: () => void;
  handleClick: () => void;
  scholarship: ScholarshipsType;
}) => {
  const { isMobile } = useWindowDimensions();
  const [checkBox2, setCheckBox2] = useState(false);

  return (
    <div className='flex flex-col items-center px-2 md:px-8 py-4 text-sm md:text-base text-color-secondary'>
      <img src={require('assets/icons/icon-warning.png').default} className='mb-4 h-10 md:h-15' />
      <div className='font-skadi text-xl md:text-3xl mb-4 md:mb-8'>CANCEL SCHOLARSHIP</div>
      <div className='w-full flex gap-4 items-center mb-8'>
        <StyledCheckBox
          checked={checkBox2}
          handleChange={(checked: boolean) => {
            setCheckBox2(checked);
          }}
        />
        <div className='text-color-legendary font-extrabold'>
          Total earned (if any) will be split to you and your scholar.
        </div>
      </div>
      <div>
        {checkBox2 ? (
          <DesignButton
            fullWidth
            design='yellow'
            size={isMobile ? 'medium' : 'large'}
            imageSize='small'
            className='w-32 md:w-40'
            onClick={() => {
              onClose();
              handleClick();
            }}
          >
            CONFIRM
          </DesignButton>
        ) : (
          <DesignButton
            fullWidth
            design='gray'
            size={isMobile ? 'medium' : 'large'}
            imageSize='small'
            className='w-32 md:w-40'
          >
            CONFIRM
          </DesignButton>
        )}
      </div>
      <CloseButton onClick={onClose} />
    </div>
  );
};

export default PopupCancelScholarship;
