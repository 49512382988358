import { Close } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';

const CloseButton = (props: IconButtonProps) => {
  return (
    <IconButton
      sx={{
        background: `url(${require(`assets/components/gray_square.png`).default}) no-repeat center/contain`,
        borderRadius: 0,
        position: 'absolute',
        top: 0,
        right: 0,
      }}
      {...props}
    >
      <Close />
    </IconButton>
  );
};

export default CloseButton;
