import { Button, Paper, Typography } from '@mui/material';
import { walletService } from 'services';

const NotLoggedInSection = () => {
  return (
    <div className='flex justify-center w-full'>
      <Paper
        elevation={0}
        className='flex flex-col items-center rounded-lg bg-dark-50 w-full py-10'
        style={{ maxWidth: 384 }}
      >
        <Typography variant='h3' className='mb-1'>
          Not Logged In
        </Typography>
        <Typography className='mb-6'>Please connect to your wallet</Typography>

        <Button
          variant='outlined'
          color='inherit'
          size='large'
          className='w-48'
          onClick={() => walletService.connectWallet()}
        >
          Connect Wallet
        </Button>
      </Paper>
    </div>
  );
};

export default NotLoggedInSection;
