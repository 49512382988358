import { Avatar, IconButton, ListItemButton, ListItemButtonProps, MenuList } from '@mui/material';
import { styled } from '@mui/styles';
import { useIsAccountOwner, useWindowDimensions } from 'hooks';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { profileRoute } from 'routes';
import { Copiable } from 'components';
import { parse, stringify } from 'query-string';
import { useQuery } from 'react-query';
import { userService } from 'services';
import { shorten } from 'utils/common';

const CustomListItem = styled(ListItemButton)({
  height: 44,
  borderRadius: 4,
  justifyContent: 'center',
  backgroundColor: '#423429',
  color: '#B7A284',
  fontWeight: 900,
  '&:hover': {
    backgroundColor: '#544940',
    color: '#F1E9DC',
  },
  '&.Mui-selected': {
    backgroundColor: '#DA8B14!important',
    color: '#423429!important',
  },
});

const MenuItem = ({ url, ...props }: { url: string } & ListItemButtonProps) => {
  const location = useLocation();
  return (
    <Link to={url}>
      <CustomListItem selected={url.includes(location.pathname)} {...props} />
    </Link>
  );
};

const ProfileNav = () => {
  const { isMobile } = useWindowDimensions();
  const { address } = useSelector(profileSelector);
  const [isAccountOwner] = useIsAccountOwner();
  const location = useLocation();
  const { owner } = parse(location.search);

  const { data: info } = useQuery(
    ['userService.fetchUserInfo', { owner, address }],
    ({ queryKey }) => {
      const { owner, address } = queryKey[1] as any;
      return userService.fetchUserInfo((owner as string) ?? address ?? '');
    },
    {
      enabled: !!address || !!owner,
    },
  );

  const newRoute = Object.entries(profileRoute).map(([key, value]) => ({
    url: value.url,
    newUrl: `${value.url}?${stringify({ owner }, { skipNull: true, skipEmptyString: true })}`,
    name: value.name,
    auth: value.auth,
  }));

  const getNextUrl = () => {
    const currentIndex = newRoute.findIndex((route) => location.pathname.includes(route.url));
    const nextIndex = currentIndex + 1 === newRoute.length ? 0 : currentIndex + 1;
    return newRoute[nextIndex].newUrl;
  };

  const getPrevUrl = () => {
    const currentIndex = newRoute.findIndex((route) => location.pathname.includes(route.url));
    const prevIndex = currentIndex === 0 ? newRoute.length - 1 : currentIndex - 1;
    return newRoute[prevIndex].newUrl;
  };

  const SampleNextArrow = () => {
    return (
      <Link to={getNextUrl()}>
        <IconButton className='absolute right-5' style={{ top: '50%', transform: 'translate(0, -50%)' }}>
          <img src={require('assets/icons/carousel-next.svg').default} className='w-7' />
        </IconButton>
      </Link>
    );
  };

  const SamplePrevArrow = () => {
    return (
      <Link to={getPrevUrl()}>
        <IconButton className='absolute left-5' style={{ top: '50%', transform: 'translate(0, -50%)' }}>
          <img src={require('assets/icons/carousel-prev.svg').default} className='w-7' />
        </IconButton>
      </Link>
    );
  };

  const AvatarSection = () => (
    <>
      <div className='relative mb-2 md:mb-0'>
        <img src={require('assets/images/frame-avatar.png').default} className='w-40 md:w-auto h-40 md:h-auto' />
        <div className='absolute inset-0 flex justify-center -z-10 pt-2 md:pt-6'>
          <Avatar src={require('assets/images/avatar-user.png').default} className='w-32 md:w-56 h-32 md:h-56' />
        </div>
      </div>
      <div className='text-white mb-2 md:mb-0 text-center'>
        <div className='text-xl md:text-3xl mb-2'>{shorten(info?.username ?? '', 15, 0, 15)}</div>
        <div className='text-sm md:text-lg flex justify-center'>
          <Copiable address={(owner as string) ?? address ?? ''} />
        </div>
      </div>
    </>
  );

  return (
    <>
      {isMobile ? (
        <div className='pt-14'>
          <div
            className='relative flex flex-col items-center pt-4'
            style={{
              background: `url(${
                require('assets/images/background-profile.png').default
              }) no-repeat top center / cover`,
            }}
          >
            <AvatarSection />
            <SampleNextArrow />
            <SamplePrevArrow />
          </div>
        </div>
      ) : (
        <div className='sticky top-28 flex flex-col items-center py-10 w-80 rounded-lg bg-dark-50'>
          <AvatarSection />
          <MenuList className='flex flex-col gap-3 w-full mt-6 px-10'>
            {newRoute.map(
              (route, index) =>
                ((route.auth && isAccountOwner) || !route.auth) && (
                  <MenuItem key={index} url={route.newUrl}>
                    {route.name}
                  </MenuItem>
                ),
            )}
          </MenuList>
        </div>
      )}
    </>
  );
};

export default ProfileNav;
