import { Button, Dialog, Modal, Pagination, PaginationItem, Tooltip } from '@mui/material';
import { MUIDataTableIsRowCheck } from 'mui-datatables';
import { InfoOutlined, SystemUpdateAlt, CurrencyExchange } from '@mui/icons-material';
import { customizeFloatNumber, shorten } from 'utils/common';
import { Duration } from 'luxon';
import { Link } from 'react-router-dom';
import { publicRoute } from 'routes';
import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { queryClient, scholarshipService } from 'services';
import { GetScholarshipsParams, ScholarshipsHeroType } from 'models/Scholarship';
import { CardHeroMinimized } from 'views/Home/cards';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { useSnackbar } from 'notistack';
import { OKG_HERO } from 'env';
import PopupProcessing from './PopupProcessing';
import { UserOverviewType } from 'models/User';
import PopupClaim from './PopupClaim';
import { useWindowDimensions } from 'hooks';
import PopupPayout from './PopupPayout';
import { CloseButton, DesignButton } from 'components';
import { CustomCheckbox, CustomMUIDataTable, ExpansionCell, NoItem, Status, tableHeadRender } from './TableAssigned';

const defaultSearch = {
  currentPage: 1,
  pageSize: 10,
  full: false,
  scholarshipWeb3AddressAssigned: true,
  scholarshipStatus: '5',
};

const TableAssignedEnded = ({ overview }: { overview?: UserOverviewType }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useWindowDimensions();
  const { address } = useSelector(profileSelector);
  const [isOpenPopupSuccess, setIsOpenPopupSuccess] = useState(false);
  const [isOpenPopupClaim, setIsOpenPopupClaim] = useState(false);
  const [isOpenPopupPayout, setIsOpenPopupPayout] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<any>([]);
  const [dataSearch, setDataSearch] = useState({
    ...defaultSearch,
    web3Address: address?.toLocaleLowerCase(),
  });

  const { data: scholarships, refetch } = useQuery(
    ['scholarshipService.getScholarshipManagerAssigned', dataSearch],
    async ({ queryKey }) => {
      const scholarshipsRes = await scholarshipService.getScholarship(queryKey[1] as GetScholarshipsParams);
      const newItems = scholarshipsRes.items.map((item, index) => ({
        ...item,
        registered: !item.assigneeUserName && !item.assigneeUserName,
      }));
      return { ...scholarshipsRes, items: newItems };
    },
    { keepPreviousData: true },
  );

  const { mutate: claimScholarship, isLoading: isLoadingClaim } = useMutation(scholarshipService.claimScholarship, {
    onSuccess: () => {
      refetch();
      setIsOpenPopupSuccess(true);
      queryClient.invalidateQueries('userService.fetchUserOverview');
      enqueueSnackbar('Claim successfully!', { variant: 'success' });
    },
    onError: (e: any) => {
      refetch();
      queryClient.invalidateQueries('userService.fetchUserOverview');
      enqueueSnackbar(e.response?.data?.message ?? 'Claim failed!', { variant: 'error' });
    },
  });

  const { mutate: payoutScholarship, isLoading: isLoadingPayout } = useMutation(scholarshipService.payoutScholarship, {
    onSuccess: () => {
      refetch();
      setIsOpenPopupSuccess(true);
      queryClient.invalidateQueries('userService.fetchUserOverview');
      enqueueSnackbar('Payout successfully!', { variant: 'success' });
    },
    onError: (e: any) => {
      refetch();
      queryClient.invalidateQueries('userService.fetchUserOverview');
      enqueueSnackbar(e.response?.data?.message ?? 'Payout failed!', { variant: 'error' });
    },
  });

  const columns = useMemo(
    () => [
      {
        name: 'scholarshipId',
        label: 'ID',
        options: {
          filter: true,
          sort: true,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
            return (
              <>
                <Tooltip placement='top' title={<div className='text-lg text-color-primary'>{value}</div>}>
                  <div>{shorten(value, 8, 0)}</div>
                </Tooltip>
              </>
            );
          },
        },
      },
      {
        name: 'userName',
        label: 'Account',
        options: {
          filter: true,
          sort: true,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: any, { rowIndex }: any) => (
            <Tooltip placement='top' title={<div className='text-lg text-color-primary'>{value}</div>}>
              <div>{shorten(value, 15, 0, 15)}</div>
            </Tooltip>
          ),
        },
      },
      {
        name: 'heroes',
        label: 'Heroes',
        options: {
          filter: true,
          sort: false,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: ScholarshipsHeroType[], { rowIndex }: any) => (
            <div className='flex gap-2'>
              {!!value &&
                value.map((hero: ScholarshipsHeroType, index: number) => (
                  <div key={index}>
                    <Link to={publicRoute.heroView.url({ contract: OKG_HERO, tokenId: hero.tokenId })}>
                      <CardHeroMinimized item={hero} />
                    </Link>
                  </div>
                ))}
            </div>
          ),
        },
      },
      {
        name: 'scholarWeb3Address',
        label: 'Scholar',
        options: {
          filter: true,
          sort: true,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
            const dataIndex = currentTableData[rowIndex].index;
            const scholarName = scholarships?.items[dataIndex]?.assigneeUserName ?? '';

            return (
              <div className='flex flex-col gap-1'>
                <Tooltip placement='top' title={<div className='text-lg text-color-primary'>{scholarName}</div>}>
                  <div>{shorten(scholarName, 15, 0, 15)}</div>
                </Tooltip>
                <div className='text-sm' style={{ color: '#94794D' }}>
                  {shorten(value)}
                </div>
              </div>
            );
          },
        },
      },
      {
        name: 'winRate',
        label: 'Win Rate',
        options: {
          filter: true,
          sort: true,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: any, { rowIndex }: any) => (
            <div {...(value >= 1 ? { style: { color: '#6CDD37' } } : {})}>{`${customizeFloatNumber(value ?? 0)}%`}</div>
          ),
        },
      },
      {
        name: 'ratio',
        label: '',
        options: {
          filter: true,
          sort: true,
          customHeadRender: (columnMeta: any, handleToggleColumn: any, sortOrder: any) =>
            tableHeadRender(columnMeta, handleToggleColumn, sortOrder, () => (
              <div className='flex gap-1 items-start'>
                Split Rate
                <Tooltip
                  placement='top'
                  title={
                    <div className='text-base text-color-secondary p-2'>Split Rate: % to scholar - % to manager</div>
                  }
                  componentsProps={{ tooltip: { className: 'bg-color-dark' }, arrow: { className: 'text-color-dark' } }}
                >
                  <InfoOutlined style={{ fontSize: 15 }} />
                </Tooltip>
              </div>
            )),
          customBodyRender: (value: any, { rowIndex }: any) => <div>{`${value}/${100 - value}`}</div>,
        },
      },
      {
        name: 'kab',
        label: '',
        options: {
          filter: true,
          sort: true,
          customHeadRender: (columnMeta: any, handleToggleColumn: any, sortOrder: any) =>
            tableHeadRender(columnMeta, handleToggleColumn, sortOrder, () => (
              <div className='flex gap-1 items-start'>
                gKAB Balance
                <Tooltip
                  placement='top'
                  title={
                    <div className='text-base text-color-secondary p-2'>
                      gKAB balance: Total available gKAB in the sub-account.
                    </div>
                  }
                  componentsProps={{ tooltip: { className: 'bg-color-dark' }, arrow: { className: 'text-color-dark' } }}
                >
                  <InfoOutlined style={{ fontSize: 15 }} />
                </Tooltip>
              </div>
            )),
          customBodyRender: (value: any, { rowIndex }: any) => <div>{customizeFloatNumber(value ?? 0)}</div>,
        },
      },
      {
        name: 'payToScholar',
        label: '',
        options: {
          filter: true,
          sort: true,
          customHeadRender: (columnMeta: any, handleToggleColumn: any, sortOrder: any) =>
            tableHeadRender(columnMeta, handleToggleColumn, sortOrder, () => (
              <div className='flex gap-1 items-start'>
                Pay to scholar
                <Tooltip
                  placement='top'
                  title={
                    <div className='text-base text-color-secondary p-2'>
                      Pay to scholar: Number of tokens that manager needs to pay for scholars.
                    </div>
                  }
                  componentsProps={{ tooltip: { className: 'bg-color-dark' }, arrow: { className: 'text-color-dark' } }}
                >
                  <InfoOutlined style={{ fontSize: 15 }} />
                </Tooltip>
              </div>
            )),
          customBodyRender: (value: any, { rowIndex }: any) => <div>{customizeFloatNumber(value ?? 0)}</div>,
        },
      },
      {
        name: 'scholarshipStatus',
        label: 'Status',
        options: {
          filter: true,
          sort: true,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
            const dataIndex = currentTableData[rowIndex].index;

            return (
              <div className='flex flex-col gap-1'>
                <Status status={value ?? 0} />
                <div className='text-color-primary text-sm'>
                  {`${Duration.fromObject({ seconds: scholarships?.items[dataIndex].totalPlayedSeconds ?? 0 }).toFormat(
                    'h',
                  )} hours played`}
                </div>
              </div>
            );
          },
        },
      },
    ],
    [scholarships?.items],
  );

  const mobileColumn = useMemo(
    () => [
      {
        name: 'userName',
        label: 'Account',
        options: {
          filter: true,
          sort: true,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: any, { rowIndex, currentTableData }: any) => {
            return (
              <>
                <Tooltip placement='top' title={<div className='text-lg text-color-primary'>{value}</div>}>
                  <div>{shorten(value, 5, 0, 5)}</div>
                </Tooltip>
              </>
            );
          },
        },
      },
      {
        name: 'heroes',
        label: 'Heroes',
        options: {
          filter: true,
          sort: false,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: ScholarshipsHeroType[], { rowIndex }: any) => (
            <div className='flex gap-2'>
              {!!value &&
                value.map((hero: ScholarshipsHeroType, index: number) => (
                  <div key={index}>
                    <Link to={publicRoute.heroView.url({ contract: OKG_HERO, tokenId: hero.tokenId })}>
                      <CardHeroMinimized item={hero} />
                    </Link>
                  </div>
                ))}
            </div>
          ),
        },
      },
      {
        name: 'winRate',
        label: 'Win Rate',
        options: {
          filter: true,
          sort: true,
          customHeadRender: tableHeadRender,
          customBodyRender: (value: any, { rowIndex }: any) => (
            <div {...(value >= 1 ? { style: { color: '#6CDD37' } } : {})}>{`${customizeFloatNumber(value ?? 0)}%`}</div>
          ),
        },
      },
    ],
    [],
  );

  const handleIsSelectableRow = useCallback(
    (dataIndex: number, selectedRows?: MUIDataTableIsRowCheck | undefined) => {
      let res = true;
      const scholarship = scholarships?.items[dataIndex];
      if (scholarship?.kab === 0 && scholarship?.payToScholar === 0) res = false;
      return res;
    },
    [scholarships?.items],
  );

  return (
    <>
      {!!scholarships ? (
        scholarships?.items.length === 0 ? (
          <NoItem />
        ) : (
          <>
            <div className='mb-3'>
              <div className='flex w-full gap-2 md:justify-end mb-3 px-4 md:px-0'>
                <Button
                  disabled={selectedIndex.length === 0}
                  style={{ background: '#C48A39' }}
                  variant='contained'
                  startIcon={<SystemUpdateAlt />}
                  onClick={() => {
                    setIsOpenPopupClaim(true);
                  }}
                  className='w-full md:w-auto'
                >
                  CLAIM
                </Button>
                <Button
                  disabled={selectedIndex.length === 0}
                  style={{ background: '#C46B39' }}
                  variant='contained'
                  startIcon={<CurrencyExchange />}
                  onClick={() => {
                    setIsOpenPopupPayout(true);
                  }}
                  className='w-full md:w-auto'
                >
                  PAYOUT
                </Button>
              </div>
              {isMobile ? (
                <CustomMUIDataTable
                  title=''
                  data={scholarships.items}
                  columns={mobileColumn}
                  components={{
                    Checkbox: CustomCheckbox,
                  }}
                  options={{
                    rowsSelected: selectedIndex,
                    isRowSelectable: handleIsSelectableRow,
                    onRowSelectionChange: (currentSelect, allSelected) => {
                      setSelectedIndex(allSelected.map((selected) => selected.dataIndex));
                    },
                    expandableRows: true,
                    responsive: 'standard',
                    elevation: 0,
                    download: false,
                    filter: false,
                    pagination: false,
                    search: false,
                    print: false,
                    viewColumns: false,
                    renderExpandableRow: (
                      rowData: string[],
                      rowMeta: {
                        dataIndex: number;
                        rowIndex: number;
                      },
                    ) => {
                      const expandScholarship = scholarships.items[rowMeta.dataIndex];

                      return (
                        <tr className='text-xs' style={{ background: '#1C110C' }}>
                          <td />
                          <td className='pr-12 py-2' colSpan={3}>
                            <div className='flex flex-col gap-2'>
                              <ExpansionCell
                                title='ID'
                                value={shorten(expandScholarship?.scholarshipId ?? '', 25, 0, 25)}
                              />
                              <ExpansionCell
                                title='Scholar'
                                value={shorten(expandScholarship?.assigneeUserName ?? '', 25, 0, 25)}
                                evenChild
                              />
                              <ExpansionCell
                                title={`Scholar's address`}
                                value={shorten(expandScholarship?.scholarWeb3Address ?? '', 6, 6)}
                              />
                              <ExpansionCell
                                title='Split rate'
                                value={`${expandScholarship?.ratio}/${100 - expandScholarship?.ratio}`}
                                evenChild
                              />
                              <ExpansionCell
                                title='gKAb balance'
                                value={`${customizeFloatNumber(expandScholarship?.kab ?? 0)}`}
                              />
                              <ExpansionCell
                                title='Pay to scholar'
                                value={`${customizeFloatNumber(expandScholarship?.payToScholar ?? 0)}`}
                                evenChild
                              />
                              <ExpansionCell
                                title='Status'
                                value={
                                  <div className='flex flex-col gap-1'>
                                    <Status status={expandScholarship?.scholarshipStatus ?? 0} />
                                    <div className='text-color-primary'>
                                      {`${Duration.fromObject({
                                        seconds: expandScholarship?.totalPlayedSeconds ?? 0,
                                      }).toFormat('h')} hours played`}
                                    </div>
                                  </div>
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    },
                  }}
                />
              ) : (
                <CustomMUIDataTable
                  title=''
                  data={scholarships.items}
                  columns={columns}
                  components={{
                    Checkbox: CustomCheckbox,
                  }}
                  options={{
                    rowsSelected: selectedIndex,
                    isRowSelectable: handleIsSelectableRow,
                    onRowSelectionChange: (currentSelect, allSelected) => {
                      setSelectedIndex(allSelected.map((selected) => selected.dataIndex));
                    },
                    elevation: 0,
                    download: false,
                    filter: false,
                    pagination: false,
                    search: false,
                    print: false,
                    viewColumns: false,
                  }}
                />
              )}
            </div>

            <div className='flex justify-center'>
              <Pagination
                size='large'
                color='secondary'
                page={scholarships.paginationPage}
                count={scholarships.paginationCount}
                onChange={(event, page) => {
                  if (page > 0) setDataSearch({ ...dataSearch, currentPage: page });
                }}
                renderItem={(item) => <PaginationItem {...item} classes={{ root: 'font-bold' }} />}
              />
            </div>
            <Modal
              open={isLoadingClaim || isLoadingPayout}
              style={{ background: '#06030280', backdropFilter: 'blur(4px)' }}
            >
              <>
                <PopupProcessing />
              </>
            </Modal>
            <Dialog maxWidth='md' open={isOpenPopupClaim}>
              <PopupClaim
                onClose={() => {
                  setIsOpenPopupClaim(false);
                }}
                handleClick={() =>
                  claimScholarship({
                    scholarshipIds: selectedIndex.map((selected: any) => scholarships.items[selected].scholarshipId),
                  })
                }
                selectedScholarship={selectedIndex.map((selected: any) => scholarships.items[selected])}
              />
            </Dialog>
            <Dialog maxWidth='md' open={isOpenPopupPayout}>
              <PopupPayout
                onClose={() => {
                  setIsOpenPopupPayout(false);
                }}
                handleClick={() =>
                  payoutScholarship({
                    scholarshipIds: selectedIndex.map((selected: any) => scholarships.items[selected].scholarshipId),
                  })
                }
                selectedScholarship={selectedIndex.map((selected: any) => scholarships.items[selected])}
                maxLimit={overview?.kab ?? 0}
              />
            </Dialog>
            <Dialog maxWidth='md' open={isOpenPopupSuccess}>
              <div className='flex flex-col items-center py-5 md:p-14'>
                <img src={require('assets/icons/icon-success.png').default} className='mb-7 md:mb-8 w-20 md:w-32' />
                <div className='text-white font-skadi text-xl md:text-3xl text-center mb-2.5'>SUCCESS!</div>
                <div className='text-xs md:text-xl text-center mb-4 md:mb-10 px-5 md:px-0'>
                  Your transaction has been completed
                </div>
                <DesignButton
                  fullWidth
                  design='yellow'
                  size='large'
                  imageSize='small'
                  className='w-48'
                  onClick={() => {
                    setIsOpenPopupSuccess(false);
                  }}
                >
                  DONE
                </DesignButton>
                <CloseButton
                  onClick={() => {
                    setIsOpenPopupSuccess(false);
                  }}
                />
              </div>
            </Dialog>
          </>
        )
      ) : null}
    </>
  );
};

export default TableAssignedEnded;
