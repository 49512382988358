import { Tooltip } from '@mui/material';
import { ManaCost, SlidingText } from 'components';
import { isNil } from 'lodash';
import { HeroType } from 'models/Hero';
import { prop, propOr } from 'ramda';
import { currencyFormat, customizeFloatNumber } from 'utils/common';

const skillTypeList = [{ code: 'spell' }, { code: 'building' }];

const InfoTag = ({ text }: { text: string }) => (
  <div className='rounded-xl px-2 py-1 flex items-center text-xs font-black' style={{ background: '#6E4C3C' }}>
    {text}
  </div>
);

const InfoLine = ({ text, value }: { text: string; value: string }) => (
  <div className='rounded-lg p-1 grid grid-cols-2 gap-1 text-xs' style={{ background: '#372218' }}>
    <div style={{ color: '#B19B8A' }}>
      <SlidingText text={text} />
    </div>
    <div className='font-extrabold text-right'>
      <SlidingText text={value} />
    </div>
  </div>
);

const componentsProps = {
  tooltip: {
    className: 'bg-color-dark text-sm text-color-secondary p-3.5',
    style: {
      border: '1px solid #574239',
      backdropFilter: 'blur(10px)',
      borderRadius: 5,
    },
  },
  arrow: {
    className: 'text-color-dark',
    style: {
      backdropFilter: 'blur(10px)',
    },
  },
};

const CardSkill = ({ item }: { item: HeroType }) => {
  const activeSkill = item.externalAttrs?.skills?.skillActive;
  const type = activeSkill?.type;
  const stats = activeSkill?.currentStats;

  const getPropOfStats = (propName: string) => propOr<string, any, any>('-', propName, stats).toString();
  // const getPropOfStatsAndFormatNumber = (propName: string) => {
  //   const val = prop<number>(propName, stats);
  //   return isNil(val) ? '-' : customizeFloatNumber(val, 1);
  // };

  // const getPropOfStatsAndFormatNumber =   (propName: string) => compose(
  //   ifElse(
  //     isNil,
  //     always('-'),
  //     customizeFloatNumber(__, 1)
  //   ),
  //   prop<number>(propName)
  // )(stats)

  const getPropOfStatsAndFormatNumber = (propName: string) => {
    const val = prop<number>(propName, stats);
    return isNil(val) ? '-' : customizeFloatNumber(val, 1);
  };

  return (
    <div className='px-2 py-3 text-color-secondary card-hover-skill w-full' style={{ background: '#51372A', borderRadius: 10 }}>
      <div className='flex gap-3 mb-4'>
        <div className='relative flex-none' style={{ height: 'fit-content' }}>
          <img src={activeSkill?.icon} style={{ height: 142, width: 108 }} />
          <div className='absolute bottom-1.5 left-1.5'>
            <ManaCost cost={activeSkill?.currentStats?.cost?.toString() ?? '-'} active={true} />
          </div>
        </div>
        <div className='flex-1 flex flex-col gap-2 overflow-hidden'>
          <Tooltip placement='top' componentsProps={componentsProps} title={activeSkill?.name}>
            <div
              className={`font-extrabold overflow-hidden whitespace-nowrap text-color-${activeSkill?.rarity?.toLowerCase()}`}
              style={{ textOverflow: 'ellipsis', paddingLeft: 1 }}
            >
              {activeSkill?.name}
            </div>
          </Tooltip>
          <div className='flex gap-2'>
            <InfoTag text={`Lv. ${activeSkill?.level ?? '-'}`} />
            <InfoTag text={type ?? '-'} />
          </div>
          <Tooltip placement='top' componentsProps={componentsProps} title={activeSkill?.description}>
            <div
              className='text-xs overflow-hidden'
              style={{ color: '#B19B8A', display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical' }}
            >
              {activeSkill?.description}
            </div>
          </Tooltip>
        </div>
      </div>
      <div
        className='rounded-lg p-2 grid grid-cols-2 gap-x-4 gap-y-2 mb-5'
        style={{ background: '#432A1E', border: '1px solid #574239' }}
      >
        {type?.toLowerCase() === skillTypeList[0].code ? (
          (stats?.dmg ?? 0) < 0 ? (
            <div className='text-xs col-span-2' style={{ color: '#B19B8A', height: 88 }}>
              Cost of <span className='text-color-legendary'>previous card</span> played{' '}
              <span className='text-color-legendary'>+1</span>
            </div>
          ) : (
            <>
              <InfoLine text='Target' value={getPropOfStats('targets')} />
              <InfoLine text='Damage' value={getPropOfStatsAndFormatNumber('dmg')} />
              <InfoLine text='Tower damage' value={getPropOfStatsAndFormatNumber('buildingDmg')} />
              <InfoLine text='Radius' value={getPropOfStats('radius')} />
              <InfoLine text='Deploy time' value={getPropOfStats('deployTime')} />
            </>
          )
        ) : (
          <>
            <InfoLine text='Target' value={getPropOfStats('targets')} />
            <InfoLine text='HP' value={getPropOfStatsAndFormatNumber('hp')} />
            <InfoLine text='Damage' value={getPropOfStatsAndFormatNumber('damage')} />
            <InfoLine text='Speed' value={getPropOfStats('attackSpeed')} />
            <InfoLine text='Range' value={getPropOfStats('attackRange')} />
            <InfoLine text='Lifetime' value={getPropOfStats('lifeTime')} />
          </>
        )}
      </div>
      <div className='flex items-center justify-center gap-1 text-2xl text-color-secondary break-all font-bold px-1 w-full'>
        <img src={require('assets/icons/OKG-token.png').default} className='h-6' />
        {item?.sale?.price ? currencyFormat(Number(item.sale.price)) : '-'}
      </div>
    </div>
  );
};

export default CardSkill;
