import { lazyLoadThenCreateElement } from 'utils';

type RouteType = {
  path: string;
  url: (query?: any) => string;
  element: JSX.Element;
};

type PublicRouteType = {
  [key: string]: RouteType;
};

export const publicRoute: PublicRouteType = {
  // home: {
  //   path: '/explore',
  //   url: () => `/explore`,
  //   element: lazyLoadThenCreateElement(() => import('views/Home'), 'Home'),
  // },
  profile: {
    path: '/profile/*',
    url: () => `/profile`,
    element: lazyLoadThenCreateElement(() => import('views/Profile'), 'Profile'),
  },
  heroView: {
    path: '/heroes/:contract/:tokenId',
    url: ({ contract, tokenId }) => `/heroes/${contract}/${tokenId}`,
    element: lazyLoadThenCreateElement(() => import('views/HeroView'), 'HeroView'),
  },
  cocoonView: {
    path: '/cocoons/:contract/:tokenId',
    url: ({ contract, tokenId }) => `/cocoons/${contract}/${tokenId}`,
    element: lazyLoadThenCreateElement(() => import('views/CocoonView'), 'CocoonView'),
  },
  // comingSoon: {
  //   path: '/coming-soon',
  //   url: () => `/coming-soon`,
  //   element: lazyLoadThenCreateElement(() => import('views/ComingSoon'), 'ComingSoon'),
  // },
  // rewards: {
  //   path: '/rewards',
  //   url: () => `/rewards`,
  //   element: lazyLoadThenCreateElement(() => import('views/Rewards'), 'Rewards'),
  // },
  marketplace: {
    path: '/marketplace',
    url: () => `/marketplace`,
    element: lazyLoadThenCreateElement(() => import('views/Marketplace'), 'Marketplace'),
  },
  breeding: {
    path: '/breeding',
    url: () => `/breeding`,
    element: lazyLoadThenCreateElement(() => import('views/Breeding'), 'Breeding'),
  },
  // claimGift: {
  //   path: '/claim-gift',
  //   url: () => `/claim-gift`,
  //   element: lazyLoadThenCreateElement(() => import('views/ClaimGift'), 'ClaimGift'),
  // },
  // refer: {
  //   path: '/refer/:referCode',
  //   url: ({ referCode }) => `/refer/${referCode}`,
  //   element: lazyLoadThenCreateElement(() => import('views/Refer'), 'Refer'),
  // },
  scholarship: {
    path: '/scholarship/*',
    url: () => `/scholarship`,
    element: lazyLoadThenCreateElement(() => import('views/Scholarship'), 'Scholarship'),
  },
  dailyBonus: {
    path: '/daily-bonus',
    url: () => '/daily-bonus',
    element: lazyLoadThenCreateElement(() => import('views/DailyBonus'), 'DailyBonus'),
  },
};
