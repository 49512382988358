import { Skeleton, styled } from '@mui/material';
import { useWindowDimensions } from 'hooks';

const CustomSkeleton = styled(Skeleton)`
  border-radius: 8px;
`;

const CardHeroSkeleton = () => {
  const { isMobile } = useWindowDimensions();

  return (
    <div style={{ background: '#3B2F25', borderRadius: 11, padding: 7 }}>
      {isMobile ? (
        <div className='flex gap-5'>
          <CustomSkeleton variant='rectangular' width={90} height={90} className='mb-2' />
          <div className='flex-1'>
            <CustomSkeleton variant='rectangular' height={17} className='mb-2.5' />
            <div className='grid grid-cols-3 gap-x-3.5 gap-y-2 mb-5'>
              {[...Array(6)].map((_, index) => (
                <CustomSkeleton variant='rectangular' height={17} key={index} />
              ))}
            </div>
            <CustomSkeleton variant='rectangular' height={23} />
          </div>
        </div>
      ) : (
        <>
          <CustomSkeleton variant='rectangular' height={233} className='mb-2' />
          <div className='grid grid-cols-2 gap-2 mb-4'>
            {[...Array(4)].map((_, index) => (
              <CustomSkeleton variant='rectangular' height={16} key={index} />
            ))}
          </div>
          <CustomSkeleton variant='rectangular' height={25} className='mb-2' />
          <div className='grid grid-cols-2 gap-2'>
            {[...Array(2)].map((_, index) => (
              <CustomSkeleton variant='rectangular' height={36} key={index} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CardHeroSkeleton;
