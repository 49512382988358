import { Button, Modal, styled, TextField } from '@mui/material';
import { CloseButton, DesignButton } from 'components';
import { useWindowDimensions } from 'hooks';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { queryClient, withdrawalService } from 'services';
import { TokenEnum } from 'services/helper';
import { customizeFloatNumber } from 'utils/common';
import PopupProcessing from 'views/Scholarship/components/PopupProcessing';

const AmountField = styled(TextField)`
  border-radius: 8px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & .MuiOutlinedInput-root {
    background: #b7a284;
    border-radius: 8px;
  }
  & .MuiOutlinedInput-input {
    font-family: 'Avenir';
    font-weight: 800;
    font-size: 24px;
    color: #392609;
    padding: 8px 16px;
    @media (max-width: 800px) {
      font-size: 16px;
      padding: 10px;
    }
  }
`;

type PopupWithdrawalProps = { maxAmount: number | undefined; gameToken: TokenEnum; onClose: () => void };

const PopupWithdrawal: React.FC<PopupWithdrawalProps> = ({ onClose, maxAmount, gameToken }) => {
  const { isMobile } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const { control, setValue, handleSubmit } = useForm({ mode: 'onChange' });
  const [isBadRequestWithdraw, setIsBadRequestWithdraw] = useState(false);
  const upperCaseToken = gameToken?.toUpperCase();
  const {
    mutate: withdrawal,
    isSuccess,
    isLoading,
  } = useMutation(withdrawalService.withdrawal, {
    onSuccess: () => {
      enqueueSnackbar('Withdrawal successfully!', { variant: 'success' });
      queryClient.invalidateQueries('withdrawalService.getWithdrawalHistories');
      // queryClient.invalidateQueries(`withdrawalService.getBalancegKAB`);
      // queryClient.invalidateQueries('withdrawalService.getBalancegOKG');
      queryClient.invalidateQueries('fetchTokenBalance');
    },
    onError: (e: any) => {
      if (e.response?.data?.message === 'Bad request') {
        setIsBadRequestWithdraw(true);
        setTimeout(() => setIsBadRequestWithdraw(false), 60000);
      } else {
        enqueueSnackbar(e.response?.data?.message ?? 'Withdrawal failed!', { variant: 'error' });
      }
    },
  });

  const maxLimit = useMemo(() => {
    return Number(customizeFloatNumber(maxAmount as number));
  }, [maxAmount]);
  console.log(maxAmount);
  return (
    <div className='px-2 md:px-8 py-4 md:py-10 text-color-secondary' style={{ maxWidth: 612 }}>
      {isSuccess ? (
        <div className='flex flex-col items-center'>
          <img src={require('assets/icons/icon-success.png').default} className='mb-7 md:mb-8 w-20 md:w-32' />
          <div className='text-white font-skadi text-xl md:text-3xl text-center mb-2.5'>SUBMITTED SUCCESSFULLY!</div>
          <div className='text-xs md:text-xl text-center mb-4 md:mb-8 px-5 md:px-0'>
            Your withdrawal request has been submitted.
            <br />
            Check your withdrawal status progress at the history.
          </div>
          <DesignButton
            fullWidth
            design='yellow'
            size='large'
            imageSize='small'
            className='w-48'
            onClick={() => {
              onClose();
            }}
          >
            DONE
          </DesignButton>
        </div>
      ) : (
        <>
          <div className='font-skadi text-3xl mb-4 md:mb-8 text-center'>Withdraw</div>
          <div className='font-skadi mb-4 md:mb-8'>
            Exchange ingame g{upperCaseToken} to {upperCaseToken} token
          </div>
          <Controller
            name='amount'
            defaultValue=''
            control={control}
            rules={{
              required: true,
              pattern: /^\d*\.?\d*$/,
              min: gameToken === TokenEnum.KAB ? 1000 : 300,
              max: maxLimit,
            }}
            render={({ field, fieldState: { invalid, error } }) => {
              let mes = `The minimum withdrawal amount is ${
                gameToken === TokenEnum.KAB ? '1,000' : '300'
              } g${upperCaseToken}`;
              let color = '#EF4444';
              if (error?.type === 'max') {
                mes = `Your maximum amount is ${maxLimit.toLocaleString()} g${upperCaseToken}`;
              }

              return (
                <div className='mb-4 md:mb-8 text-color-primary'>
                  <div className='text-sm md:text-base font-black mb-2 md:mb-1 gap-2 flex justify-between break-all'>
                    <div className='flex-none'>
                      AMOUNT <span style={{ color: '#FF613F' }}>*</span>
                    </div>
                    <div>{`AVAILABLE: ${maxLimit.toLocaleString()} g${upperCaseToken}`}</div>
                  </div>
                  <AmountField
                    {...field}
                    fullWidth
                    variant='outlined'
                    placeholder='0'
                    size='medium'
                    error={invalid}
                    InputProps={{
                      endAdornment: (
                        <div
                          className='flex gap-2 md:pr-5 md:text-xl font-avenir font-bold items-center'
                          style={{ color: '#392609' }}
                        >
                          <Button
                            variant='contained'
                            className='font-bold text-color-primary text-xs md:text-sm'
                            style={{ background: '#86632D', borderRadius: 8 }}
                            onClick={() => setValue('amount', maxLimit, { shouldValidate: true })}
                          >
                            Max
                          </Button>
                          g{upperCaseToken}
                        </div>
                      ),
                      type: 'number',
                      onKeyDown: (el: any) => {
                        if (
                          el.which === 189 ||
                          el.which === 190 ||
                          el.which === 109 ||
                          el.which === 110 ||
                          el.which === 107 ||
                          el.which === 187 ||
                          el.which === 69 ||
                          el.which === 231
                        )
                          el.preventDefault();
                      },
                    }}
                  />
                  {invalid && (
                    <div className='text-tiny md:text-sm mt-1' style={{ color }}>
                      {mes}
                    </div>
                  )}
                  {isBadRequestWithdraw && (
                    <div className='text-tiny md:text-sm mt-1' style={{ color }}>
                      Try again in 1 minute.
                    </div>
                  )}
                </div>
              );
            }}
          />
          {isBadRequestWithdraw ? (
            <DesignButton fullWidth design='gray' className='mb-2' size={isMobile ? 'medium' : 'large'}>
              WITHDRAW
            </DesignButton>
          ) : (
            <DesignButton
              fullWidth
              design='yellow'
              className='mb-2'
              size={isMobile ? 'medium' : 'large'}
              onClick={() => {
                handleSubmit((values: any) => {
                  withdrawal({ token: gameToken, amount: Number(values?.amount) ?? 0 });
                })();
              }}
            >
              WITHDRAW
            </DesignButton>
          )}
          <div className='flex flex-col gap-1 text-color-primary'>
            <div>Minimum withdrawal amount: 1,000 g{upperCaseToken}</div>
          </div>
        </>
      )}
      <CloseButton onClick={onClose} />
      <Modal open={isLoading} style={{ background: '#06030280', backdropFilter: 'blur(4px)' }}>
        <>
          <PopupProcessing />
        </>
      </Modal>
    </div>
  );
};

export default PopupWithdrawal;
