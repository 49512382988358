import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { CloseButton, DesignButton, PerfectScrollbar } from 'components';
import { erc721Contract, RedemptionContract } from 'contracts';
import { REDEEM_CONTRACT } from 'env';
import { CocoonType } from 'models/Cocoon';
import { PopupController } from 'models/Common';
import { HeroType } from 'models/Hero';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { nftService, queryClient } from 'services';
import { CardCocoon, CardHero } from 'views/Home/cards';
import { useWindowDimensions } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { profileRoute, publicRoute } from 'routes';

type PopupProps = PopupController & {
  items: CocoonType[];
};

const PopupOpenCocoon = ({ items: cocoons, onSuccess, onClose }: PopupProps) => {
  const { isMobile } = useWindowDimensions();
  const isStandardCocoon = cocoons[0]?.type === 'standard-cocoon';
  const navigate = useNavigate();
  const { address } = useSelector(profileSelector);

  const [isAnimate, setIsAnimate] = useState(false);
  const [heroes, setHeroes] = useState<HeroType[]>([]);

  const {
    mutate: openCocoon,
    isLoading,
    isSuccess,
  } = useMutation(
    async () => {
      const cocoonContract = cocoons[0].contract;

      const isApprovedForAll = await erc721Contract(cocoonContract)
        .methods.isApprovedForAll(address, REDEEM_CONTRACT)
        .call();
      if (!isApprovedForAll) {
        await erc721Contract(cocoonContract).methods.setApprovalForAll(REDEEM_CONTRACT, true).send({ from: address });
      }

      const open = await nftService.openCocoon({
        contract: cocoonContract,
        tokenIds: cocoons.map((item) => item.tokenId),
      });
      await RedemptionContract(REDEEM_CONTRACT!)
        .methods.redeem(open.cocoonContract, open.heroContract, open.cocoonIds, open.heroIds, open.signature)
        .send({ from: address });
      await new Promise((resolve) => setTimeout(resolve, 50000));

      setIsAnimate(true);
      await new Promise((resolve) => setTimeout(resolve, 8000));

      const fetchHeroes = open.heroIds.map((heroId) =>
        nftService.getHeroToken({ contract: open.heroContract, tokenId: heroId }),
      );
      await Promise.all(fetchHeroes).then(setHeroes);
      setIsAnimate(false);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('nftService.fetchCocoons');
        queryClient.invalidateQueries('nftService.fetchHeroes');
        onSuccess?.();
      },
    },
  );

  const handleClickInventory = () => {
    navigate({
      pathname: profileRoute.inventory.url,
      search: 'tab=heroes',
    });
    onClose();
  };

  const handleViewHero = () => {
    if (heroes.length > 0) {
      navigate({
        pathname: publicRoute.heroView.url({ contract: heroes[0].contract, tokenId: heroes[0].tokenId }),
      });
    }
    onClose();
  };

  const handleClickConfirm = () => {
    openCocoon();
  };

  return (
    <>
      <DialogTitle className='font-skadi text-xl md:text-2xl'>
        {isSuccess ? 'CONGRATULATION' : 'OPEN COCOONS'}
      </DialogTitle>
      <DialogContent className='flex justify-center' style={{ minWidth: isMobile ? 0 : 480 }}>
        {!isSuccess ? (
          <PerfectScrollbar style={{ height: 341 + 20 }}>
            <Grid container spacing={3} className='md:flex-nowrap'>
              {cocoons.map((item) => (
                <Grid item key={item.tokenId}>
                  <div className='md:w-60'>
                    <CardCocoon item={item} notDisplaySale />
                  </div>
                </Grid>
              ))}
            </Grid>
          </PerfectScrollbar>
        ) : (
          <PerfectScrollbar style={{ height: 340 }}>
            <Grid container spacing={3} className='md:flex-nowrap'>
              {heroes
                .filter((valid) => valid)
                .map((item) => (
                  <Grid item key={item.tokenId}>
                    <div className='md:w-60'>
                      <CardHero item={item} notDisplaySale />
                    </div>
                  </Grid>
                ))}
            </Grid>
          </PerfectScrollbar>
          // <div className='text-color-secondary md:text-lg text-center pt-6 pb-3 md:px-8' style={{ maxWidth: 540 }}>
          //   Once summoned, the Cocoon instantly bursts into presence by the Seven Lights the Almighty. Floating in the air, the Cocoon accumulates power from the Land of Ookeenga to forge a new Hero.
          //   <br />
          //   <br />
          //   Behold, your Hero shall come in a powerful blast!
          // </div>
        )}
        {isAnimate && (
          <video
            autoPlay
            className='fixed inset-0 z-10'
            style={{
              width: '100vw',
              height: '100vh',
              objectFit: 'cover',
            }}
          >
            <source
              src={
                isStandardCocoon
                  ? require(`assets/videos/open-standard-cocoon.mp4`).default
                  : require(`assets/videos/gacha-cocoon.mp4`).default
              }
              type='video/mp4'
            />
            Your browser does not support HTML5 video.
          </video>
        )}
      </DialogContent>
      <DialogActions className='flex flex-col justify-center'>
        {isSuccess ? (
          heroes.length > 1 ? (
            <DesignButton design='green' className='w-48' loading={isLoading} onClick={handleClickInventory}>
              TO INVENTORY
            </DesignButton>
          ) : (
            <DesignButton design='green' className='w-48' loading={isLoading} onClick={handleViewHero}>
              VIEW HERO
            </DesignButton>
          )
        ) : (
          // <DesignButton design='green' className='w-48 mb-3' loading={isLoading} onClick={onClose}>
          //   GOT IT
          // </DesignButton>
          <>
            <DesignButton className='w-48 mb-3' loading={isLoading} onClick={handleClickConfirm}>
              OPEN
            </DesignButton>
            <div className={`text-sm text-color-primary mb-1 text-center ${isLoading ? '' : 'hidden'}`}>
              Hero minting time may take 1-4 minutes. Please be patient!
            </div>
          </>
        )}
      </DialogActions>
      <CloseButton className={isLoading ? 'hidden' : ''} onClick={onClose} />
    </>
  );
};

export default PopupOpenCocoon;
