import { parse } from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';

const useIsAccountOwner = () => {
  const { search } = useLocation();
  const { owner } = parse(search);
  const { address, isLoggedIn } = useSelector(profileSelector);
  const isAccountOwner = (isLoggedIn && address === owner) || (isLoggedIn && !owner);

  return [isAccountOwner] as [boolean];
};

export default useIsAccountOwner;
