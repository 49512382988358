import React from 'react';
import { Tooltip } from '@mui/material';
import { shorten } from 'utils/common';
import { ContentCopyRounded } from '@mui/icons-material';

var timer: NodeJS.Timeout;

const Copiable = ({
  address,
  before,
  after,
  className,
  iconSize,
}: {
  address: string;
  before?: number;
  after?: number;
  className?: string;
  iconSize?: number;
}) => {
  const [isCopy, setIsCopy] = React.useState(false);
  const isAddress = address.startsWith('0x');

  const handleClickCopy = () => {
    clearTimeout(timer);
    try {
      navigator.clipboard.writeText(address);
    } catch {
    } finally {
      setIsCopy(true);
      timer = setTimeout(() => {
        setIsCopy(false);
      }, 2000);
    }
  };

  return (
    <span onClick={handleClickCopy} className={`flex items-center gap-1 ${className}`}>
      {isAddress ? shorten(address, before, after) : address}
      <Tooltip title={isCopy ? 'Copied!' : 'Copy'} onOpen={() => setIsCopy(false)}>
        <ContentCopyRounded
          fontSize='small'
          className='cursor-pointer'
          {...(iconSize ? { sx: { fontSize: iconSize } } : {})}
        />
      </Tooltip>
    </span>
  );
};

export default Copiable;
